import React from "react";
import {
  BodyContainer,
  TagWrapper,
  TagText,
  ImageMain,
  ContentWrapper,
  TextWrapper,
  TextContainer,
  TextRightContainer,
  HeaderTitle,
  SkidList,
  ContentTitle,
  OrangeLink,BioBistroOrangeText,
} from "./goldBachSeite/styledCom.js";
import LogoPanner from "../arbeit-projects/HeaderLogoPanner";
import nudelEmmaHeaderPic from "../../images/minified/Nudelemma-min.jpg";
import AgentCard from "./goldBachSeite/ArbeitAgentCard";
import { nudelEmmaAgentData } from "./goldBachSeite/projectsData.js";

import Footer from "../footer/Footer.js";

function index() {
  return (
    <BodyContainer>
      <LogoPanner />
      <TagWrapper>
        <TagText> NUDELEMMA </TagText>{" "}
      </TagWrapper>{" "}
      <br />
      <ImageMain src={nudelEmmaHeaderPic} />{" "}
      <ContentWrapper>
        <HeaderTitle>
          Die Nudel-to-go. Mithilfe eines Dampfverfahrens bereiten wir in
          wenigen Minuten verschiedene Nudelgerichte mit unseren Hausmacher
          Bio-Nudeln und hausgemachten Bio-Saucen zu
        </HeaderTitle>{" "}
        <TextWrapper style={{ lineHeight: "25px", paddingTop: "20px" }}>
          <TextContainer>
            <ContentTitle style={{ paddingBottom: "15px" }}>
              {" "}
              Wir bieten Ihnen{" "}
            </ContentTitle>
            <SkidList>
              <li>
                Eine Auswahl an verschiedenen Saucen Klassiker + wechselnde Variationen
              </li>
              <li> Die Hausmacher als Nudelauswahl </li>
              <li>
                {" "}
                Individuelle Anpassung: Bestelle einzelne Zutaten dazu oder ab <p >z.B. anstatt Zwiebeln entscheiden Sie sich für Kapern</p>
              </li>{" "}
            </SkidList>
          </TextContainer>{" "}
          <TextRightContainer>
            <ContentTitle style={{ paddingBottom: "10px" }}>
              {" "}
              WISSENSWERTES{" "}
            </ContentTitle>
            <SkidList>
              <li>
                Website:
                <OrangeLink href="https://www.nudel-emma.de" target="blank">
                  &nbsp; www.nudel-emma.de{" "}
                </OrangeLink>{" "}
              </li>{" "}
              <li>
                Öffnungszeiten: <BioBistroOrangeText>Mo - Do 11:30 - 14:00 und Fr 11:30 - 13:30</BioBistroOrangeText> 
              </li>{" "}
              <li>Bio zertifiziert nach DE - ÖKO - 007 </li>
            </SkidList>
          </TextRightContainer>{" "}
        </TextWrapper>{" "}
      </ContentWrapper>{" "}
      <AgentCard
        agentPic={nudelEmmaAgentData.agentPic}
        fullName={nudelEmmaAgentData.fullName}
        tel={nudelEmmaAgentData.tel}
        email={nudelEmmaAgentData.email}
        address={nudelEmmaAgentData.address}
        mapPic={nudelEmmaAgentData.mapPic}
        googleMaps={nudelEmmaAgentData.googleMaps}
      />{" "}
      <Footer />
    </BodyContainer>
  );
}

export default index;
