import kucheHeaderPic from "../../../images/minified/goldbachsection/GoldbachKueche-min.jpg";
import wascheHeaderPic from "../../../images/wascheHeaderPic.jpg";
import kunstHeaderPic from "../../../images/minified/goldbachsection/GoldbachKunst-min.jpg";
import HellwigPic from "../../../images/minified/goldbachsection/GoldbachKuecheVolkerHellwig-min.png";
import SeischabPic from "../../../images/minified/goldbachsection/GoldbachWaescheLissyeischab-min.png";
import mapPic from "../../../images/goldBachMapPic.png";
import ClassenPic from "../../../images/minified/goldbachsection/GoldbachKunstSabineClassen-min.png";
import bistroMapPic from "../../../images/bistroMapPic.png";
import alexPic from "../../../images/alexPic.png";
import WinstelPic from "../../../images/minified/NudelemmaJohannesWinstel-min.png";
import nudelEmmaMapPic from "../../../images/nudelEmmaMapPic.png";
import mathildePic from "../../../images/mathildePic.png";
import nudelLadenMapPic from "../../../images/nudelLadenMapPic.png";
import backMobilMapPic from "../../../images/kronengasse1.png";
import sabinePic from "../../../images/Sabine.png";
import RoccoPic from "../../../images/RoccoPic.png";
import nudelManufakturmapPic from "../../../images/nudelManufakturMapPic.png";
import nudelPic1 from "../../../images/nudel1.png";
import nudelPic2 from "../../../images/nudel2.png";
import nudelPic3 from "../../../images/nudel3.png";
import nudelPic4 from "../../../images/nudel4.png";
import nudelPic5 from "../../../images/nudel5.png";
import johannes from "../../../images/johannes.png";

export const dienstLeistungData = {
  agentPic: [johannes],
  fullName: "Johannes Krumbholz",
  mobile: "Mobil: 0151 12179756",
  tel: "Tel.: 07551 - 3080668",
  email: "E-mail: werkstatt@skid-ggmbh.de",
  address: "Goldbach 40 88662 Überlingen",
  mapPic: mapPic,
  googleMaps: "https://goo.gl/maps/BUrVRqjn6uyi2aeP6",
};

export const kucheData = {
  tagTitle: 'PERSONALRESTURANT "DIE SELBSTVERSORGER"',
  headerPic: kucheHeaderPic,
  linksText: [
    "Lebensmittelkunde: Welche Lebensmittel gibt es, wie werden sie bearbeitet/zubereitet/…?",
    "Umgang mit Lebensmitteln und deren Lagerung",
    "Kochen und backen",
    "Schneiden",
    "Fachgerechter Umgang mit Geräten/Messern/Werkzeugen ",
  ],
  rechtsText: [
    "Einkaufsliste und Speiseplan erstellen ",
    "Hygiene, Ordnung und Sauberkeit",
    "Arbeiten im Team",
    "Mengenberechnungen",
    "Mülltrennung",
  ],
  orangeText: "",
  agentPic: [HellwigPic],
  fullName: "Volker Hellwig",
  mobile: "Mobil: 0151 12179756",
  tel: "Tel.: 07551 - 3080668",
  email: "E-mail: werkstatt@skid-ggmbh.de",
  address: "Goldbach 40 88662 Überlingen",
  mapPic: mapPic,
  googleMaps: "https://goo.gl/maps/BUrVRqjn6uyi2aeP6",
};
export const wascheData = {
  tagTitle: "WÄSCHEWERKSTATT",
  headerPic: wascheHeaderPic,
  linksText: [
    "Sortieren der Schmutzwäsche",
    "Befüllung der Waschmaschinen",
    "Trocknen, zusammenlegen, bügeln, mangeln",
    "Wäsche aufhängen und sortieren",
  ],
  rechtsText: [
    "Reparaturarbeiten",
    "Hygienebewusstsein",
    "Feinmotorik",
    "Spaß an ruhiger, wiederholender Arbeit",
  ],
  orangeText: "",
  agentPic: [SeischabPic],
  fullName: "Lissy Seischab",
  mobile: "Mobil: 0151 12179756",
  tel: "Tel.: 07551 - 3080668",
  email: "E-mail: werkstatt@skid-ggmbh.de",
  address: "Goldbach 40 88662 Überlingen",
  mapPic: mapPic,
  googleMaps: "https://goo.gl/maps/BUrVRqjn6uyi2aeP6",
};



export const goldBachKunstData = {
  tagTitle: "Kunstatelier Goldbach",
  headerPic: kunstHeaderPic,
  linksText: [
    "Offenes Atelier",
    "Grundkurse in 2D- und 3D-Gestaltung",
    "Unterschiedliche Materialien und Techniken",
    "Workshops",
  ],
  rechtsText: [""],
  orangeText: "Seit November 2015 besteht das Atelier Goldbach und bietet Raum für Gestaltung und Kreativität",
  agentPic: [ClassenPic],
  fullName: "Sabine Claßen",
  mobile: "",
  tel: "Tel.: 07551 - 3080668",
  email: "E-mail: kunst@skid-ggmbh.de",
  address: "Goldbach 40 88662 Überlingen",
  mapPic: mapPic,
  googleMaps: "https://goo.gl/maps/BUrVRqjn6uyi2aeP6",
};
export const bioBistroAgentData = {
  agentPic: [alexPic],
  fullName: "Alexander Metzler",
  tel: "Tel.: 07551 - 936245",
  email: "E-mail: skids-bistro@skid-ggmbh.de",
  address: "Steinhausgasse 2 (gegenüber Stadtbücherei) 88662 Überlingen",
  mapPic: bistroMapPic,
  googleMaps: "https://goo.gl/maps/jTTiwG9hjp9oAdG36",
};
export const nudelEmmaAgentData = {
  agentPic: [WinstelPic],
  fullName: "Johannes Winstel",
  tel: "Tel.: 07551 - 8313690 ",
  email: "E-mail: info@ueberlinger-nudelmanufaktur.de",
  address: "Hochbildstr. 21 88662 ÜBERLINGEN",
  mapPic: nudelEmmaMapPic,
  googleMaps: "https://goo.gl/maps/hNSG4stfuuB8ByrB7",
};
export const nudelLadenAgentData = {
  agentPic: [mathildePic],
  fullName: "Mathilde Balser",
  tel: "Tel.: 07551 9474214",
  email: "E-mail: nudelladen@skid-ggmbh.de",
  address: "Christophpstr. 15 88662 Überlingen",
  mapPic: nudelLadenMapPic,
  googleMaps: "https://goo.gl/maps/pyKJNZZTajCQiFZG8",
};
export const backMobilAgentData = {
  agentPic: [sabinePic],
  fullName: "Sabine Wein",
  tel: "Tel.: 07551 - 93798 10",
  email: "E-mail: info@skid-ggmbh.de",
  address: "Kronengasse 1 88662 Überlingen",
  mapPic: backMobilMapPic,
  googleMaps: "https://goo.gl/maps/RZMWMVHUzuuxzuZ49",
};

export const nudelManufakturData = {
 nudelPic1:nudelPic1,
 nudelPic2:nudelPic2,
 nudelPic3:nudelPic3,
 nudelPic4:nudelPic4,
 nudelPic5:nudelPic5,
  agentPic: [RoccoPic],
  fullName: "Rocco Plaul",
  tel: "Tel.: 07551 - 83 13 690",
  email: "E-mail: info@ueberlinger-nudelmanufaktur.de",
  address: "Hochbildstr. 21 88662 Überlingen",
  mapPic: nudelManufakturmapPic,
  googleMaps: "https://goo.gl/maps/UMgWwC7iQ8f3ZWd79",
};