import React from "react";
import {
  BodyContainer,
  TagWrapper,
  TagText,
  ImageMain,
  ContentWrapper,
  TextWrapper,
  TextContainer,
  TextRightContainer,
  HeaderTitle,
  SkidList,
  BackMobilProsUlWrapper,
  ContentTitle,
  OrangeText,
} from "./goldBachSeite/styledCom.js";
import LogoPanner from "../arbeit-projects/HeaderLogoPanner";
import backmobilHeaderPic from "../../images/backmobilHeaderPic.jpg";
import AgentCard from "./goldBachSeite/ArbeitAgentCard";
import { backMobilAgentData } from "./goldBachSeite/projectsData.js";

import Footer from "../footer/Footer.js";

function index() {
  return (
    <BodyContainer>
      <LogoPanner />
      <TagWrapper>
        <TagText> BACKMOBIL </TagText>{" "}
      </TagWrapper>{" "}
      <br />
      <ImageMain src={backmobilHeaderPic} />{" "}
      <ContentWrapper>
        <HeaderTitle>
          Das SKID Backmobil bietet Flammkuchen aus dem Holzofen mit
          unterschiedlichen Belägen
        </HeaderTitle>{" "}
        <ContentTitle style={{ padding: "15px 0px" }}>
          Unser Angebot:
        </ContentTitle>
        <SkidList>
          <BackMobilProsUlWrapper>
            <div>
              {" "}
              <li>Zwiebel & Speck </li>
              <li>Spinat & Gorgonzola </li>
            </div>
            <div>
              <li>Tomate & Mozzarella</li>
              <li>Apfel & Zimt</li>
            </div>
          </BackMobilProsUlWrapper>
        </SkidList>
        <TextWrapper style={{ lineHeight: "25px", paddingTop: "20px" }}>
          <TextContainer style={{ alignItems: "flex-end", display: "flex" }}>
          <OrangeText>  <p>Die Beläge können den Jahreszeiten entsprechend variieren.</p></OrangeText> 
          </TextContainer>{" "}
          <TextRightContainer>
            <p>
              <OrangeText>Interesse ?</OrangeText> Dann rufen Sie uns an.
            </p>
            <p>Das Backmobil kann mit Personal gemietet werden.</p>
          </TextRightContainer>{" "}
        </TextWrapper>{" "}
      </ContentWrapper>{" "}
      <AgentCard
        agentPic={backMobilAgentData.agentPic}
        fullName={backMobilAgentData.fullName}
        tel={backMobilAgentData.tel}
        email={backMobilAgentData.email}
        address={backMobilAgentData.address}
        mapPic={backMobilAgentData.mapPic}
        googleMaps={backMobilAgentData.googleMaps}
      />{" "}
      <Footer />
    </BodyContainer>
  );
}

export default index;
