import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import {
  KunstBody,
  AboutUSTag,
  AboutUSTagText,
  KunstImg,
  KunstTextContainer,
  KunstTitle,
  KunstText,
  KunstTextArea
} from "../../styledComponents";
import kunstPic from "../../images/KunstStartseite.jpg";
import InsideKunst from "./KunstInetrTeamWork.js";
import useWindowSize from "../../components/useWindowSize";
import LightGreenButton from "../../components/shared/Button";

const Kunst = () => {
  const [moreOnKunst, setMoreOnKunst] = useState(false);

  const toggelReadMore = useCallback(() => {
    setMoreOnKunst(!moreOnKunst);
  }, [moreOnKunst]);

  const size = useWindowSize();
  useEffect(() => {
    document.getElementById("Kunst").addEventListener("click", toggelReadMore);
  }, [size.width, toggelReadMore]);

  return (
    <>
      <KunstBody id="KunstSection">
        <br />
        <AboutUSTag>
          <AboutUSTagText> Kunst </AboutUSTagText>{" "}
        </AboutUSTag>{" "}
        <br />
        <KunstImg src={kunstPic} />{" "}
        <KunstTextContainer>
          <KunstTextArea>
            <KunstTitle> Kunst im Alltag </KunstTitle>{" "}
            <div>
              <KunstText>
                Phantasievolles Gestalten ist ein wesentlicher Bestandteil der
                persönlichen Identifikation und Ausdrucksweise.
              </KunstText>
              <KunstText>
                Das Kunstwerk zeigt sich losgelöst von der unmittelbaren
                Befindlichkeit des Künstlers.
              </KunstText>
              <KunstText>
                Seit November 2015 besteht das Atelier Goldbach und bietet Raum
                für Gestaltung und Kreativität.{" "}
              </KunstText>
            </div>
            <KunstTitle className="pt-2"> Unser Angebot </KunstTitle>{" "}
            <div>
              <KunstText>
                Atelierplätze im Kunstatelier Goldbach
              </KunstText>
              <KunstText>
                Kreatives Arbeiten
              </KunstText>
              <KunstText>
                Workshops und Kurse
              </KunstText>
              <KunstText>
                Internationale Projekte mit Partnerorganisationen in Luxemburg,
                England und Irland{" "}
              </KunstText>
            </div>
          </KunstTextArea>{" "}
          {moreOnKunst === false && (
            <LightGreenButton noWrapper={true} classes="mr-0" action={toggelReadMore}/>
          )}{" "}
        </KunstTextContainer>{" "}
      </KunstBody>{" "}
      {moreOnKunst === true && <InsideKunst func={toggelReadMore} />}{" "}
    </>
  );
};

export default Kunst;
