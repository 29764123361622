import styled from "styled-components";
export const rootStyles = {
  fontSize24: "24px",
  fontSize20: "20px",
  fontSize14: "14px",
  lineHeight: "25px",
  fontWeight600:600,
  lightGreen:"#A5CF9D",
  orange: "#FE5F04",
  margin:"30px",
  white:"white",
  black: "black",
  footerPurple:"#754D5B", 
  purple: "#522032",
  agentCardPurple: "#522032",
  btnColor: "#A33B55",
  backgroundOrange: "#F6BEA2",
  tagBackgroundColor: "#272c34",
}
export const lightMode = {
    white: rootStyles.white,
    black: rootStyles.black,
    orange: rootStyles.orange,
    lightGreen: rootStyles.lightGreen,
    purple: rootStyles.purple,
    btnColor: rootStyles.btnColor,
    agentCardPurple: "#522032",
    tagBackgroundColor: rootStyles.tagBackgroundColor,
    backgroundOrange: rootStyles.backgroundOrange,
};

export const darkMode = {
    white: rootStyles.white,
    black: rootStyles.black,
    backgroundBodyColor: " #121212",
    tagBackgroundColor: rootStyles.tagBackgroundColor,
    orange: rootStyles.orange,
    purple: rootStyles.purple,
    btnColor: rootStyles.btnColor,
    agentCardPurple: "#754D5B",
    backgroundOrange: rootStyles.backgroundOrange,
};

const Robot = "sans-serif";

export const AppBody = styled.div `
  width: 100%;
  font-family: ${Robot};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  font-size: ${(props) => (props.theme.mode <= 500 ? "12px" : "20px")};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundBodyColor : lightMode.white};
  transition: all 0.25s linear;
`;

export const LogoContainer = styled.div `
  display: flex;
  justify-content: space-between;
  max-height: 125px;
  width: 100%;
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundBodyColor : lightMode.white};
`;
export const LogoWrapper = styled.div `
  margin: ${rootStyles.margin};
  margin-right: 0px;
  display: flex;
  flex-wrap: ${(props) => (props.theme.mode <= 675 ? "wrap" : "nowrap")};
  align-items: center;
  font-family: ${Robot};
  max-width: 600px;
`;
export const LogoTextWrapper = styled.div `
  display: ${(props) => (props.theme.mode <= 390 ? "none" : "")};
`;

export const BlackLogoText = styled.span `
  font-size: ${rootStyles.fontSize14};
  font-weight: bold;
  font-family: ${Robot};
  letter-spacing: 1px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
`;
export const OrangeLogoText = styled.span `
  font-size: ${rootStyles.fontSize14};
  font-weight: bold;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
  font-family: ${Robot};
  letter-spacing: 1px;
`;

export const LogoImage = styled.img `
  max-height: 65px;
  maxwidth: 180px;
  width: ${(props) => (props.theme.mode <= 450 ? "4rem" : "")};
`;
export const NavlistWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-right: 19px;
`;
export const NavlistItemWrapper = styled.div `
  margin-right: ${(props) => (props.theme.mode <= 1300 ? "15px" : "20px")};
`;
export const NavlistItemText = styled.p `
  color: ${(props) => props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  font-size: ${rootStyles.fontSize14} !important;
`;
export const MobileNavlistWrapper = styled.div `
  align-items: center;
  position: relative;
  right: 15px;
  display: flex;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.purple};
  margin-left:20px;
`;
export const MobileNavBodyWrapper = styled.div `
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.purple : lightMode.purple};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.withe};
`;

// About us Section Starts here******************************************************
export const AboutUSContainer = styled.div `
  width: 100%;
`;
export const AboutUSImage = styled.img `
  width: 100vw;
  display: grid;
`;

export const AboutUSContentContainer = styled.div `
  width: 100%;
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.backgroundBodyColor
      : lightMode.backgroundOrange};
`;
export const AboutUSTag = styled.div `
  width: max-content;
  justify-content: center;
  display: flex;
  margin-top:1em;
  background-color: ${lightMode.purple};
`;

export const AboutUSTagText = styled.p `
  color: white;
  padding: 1em;
  text-align: center;
  align-self: center;
  font-weight: bold;
`;
export const AboutUsTextFlexBox = styled.div `
  width: 95%;
  margin: auto;
  padding-bottom: 3rem;
  justify-content: space between;
  display: flex;
  flex-wrap: wrap;
`;
export const AboutUSContentWrapper = styled.div `
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "50%")};
  margin: 0 auto;
  justify-content: space between;
  display: flex;
`;
export const AboutUSTitle = styled.h1 `
  text-align: center;
  margin: auto;
  font-size: ${(props) => (props.theme.mode <= 750 ? "medium" : "38px")};
  line-height: ${(props) => (props.theme.mode <= 750 ? "2rem" : "3rem")};
  padding: ${(props) => (props.theme.mode <= 700 ? "15px 0px" : "")};
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "50%")};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
  font-family: ${Robot};
`;
export const AboutUSTextItemWrapper = styled.div `
  padding: ${(props) => (props.theme.mode <= 700 ? "7px 0px" : "7px 0px")};
`;
export const AboutUSText = styled.span `
  padding: 15px 0px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  font-family: ${Robot};
`;
export const AboutUSHighlightedText = styled.span `
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
  font-family: ${Robot};
`;
// About us Section Ends here******************************************************
// Wohnen and Freizeit Section Starts here******************************************************

export const WohnenFreizeitContentContainer = styled.div `
  width: 100%;
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundBodyColor : lightMode.white};
`;
export const WohnenFreizeitImagesContiner = styled.div `
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: transparent;
`;
export const WohnenFreizeitImageWrapper = styled.div `
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "49%")};
  display: block;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.tagBackgroundColor
      : lightMode.white};
  display:flex;
  flex-direction:column;
  gap:2em;
`;
export const WohnenFreizeitRightImageWrapper = styled.div `
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "49%")};
  display: block;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.tagBackgroundColor
      : lightMode.white};
  margin-left: ${(props) => (props.theme.mode <= 700 ? "0em" : "2%")};
  display:flex;
  flex-direction:column;
  gap:2em;
`;
export const WohnenFreizeitImage = styled.img `
  width: 100%;
`;

export const WohnenFreizeitProjectAbwTextWrapper = styled.div `
  padding: ${(props) =>(props.theme.mode <= 700 ? "1em 1em 3em 1em" : "0em 2em")};
  width: ${(props) => (props.theme.mode <= 700 ? "95%" : "95%")};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const WohnenFreizeitProjectTextWrapper = styled.div `
  padding: ${(props) => (props.theme.mode <= 700 ? "1em 1em 3em 1em" : "0em 2em")};
  width: ${(props) => (props.theme.mode <= 700 ? "95%" : "auto")};
  
`;
export const WohnenFreizeitProjectTitle = styled.p `
  font-weight: bold;
  font-size: ${rootStyles.fontSize24};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const WohnenFreizeitOrangeLetter = styled.span `
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const AbwSvgImage = styled.img `
  min-width: 5rem;
  margin-right: 5%;
  display: ${(props) => (props.theme.mode <= 700 ? "block" : "none")};
`;
export const WohnenFreizeitAngebotWrapper = styled.div `
  width: 100%;

  padding: ${(props) => (props.theme.mode <= 700 ? "15px" : "2em")};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;
export const WohnenFreizeitAngebotContainerText = styled.div `
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "50%")};

  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundBodyColor : lightMode.white};
  height: ${(props) => (props.theme.mode <= 700 ? "auto" : "100%")};
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
`;
export const WohnenFreizeitAngebotContainerImage = styled.div `
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "40%")};
  display: flex;
`;
export const WohnenFreizeitAngebotHelpOthersSVG = styled.img `
  margin: auto;
  width: 100%;
`;

export const WohnenFreizeitBtnWrapper = styled.div `
  padding: 2.5rem 0rem 1.5rem 0rem;
  width: 100%;
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.tagBackgroundColor
      : lightMode.white};
  text-align: right;
`;
export const LightGreenBtn = styled.button `
  padding: ${(props) => (props.theme.mode <= 500 ? "4px 6px" :  "0.5rem 0.75rem")};
  border: 0px;
  margin-right: 30px;
  position: relative;
  border-radius: 0.25rem;
  font-size:${rootStyles.fontSize14};
  font-weight: ${(props) => (props.theme.mode <= 500 ? "light" : "600")};
  color: ${lightMode.black};
  background-color: ${lightMode.lightGreen};
  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }

`;
export const LightGreenLink = styled.div `
  padding: ${(props) => (props.theme.mode <= 500 ? "4px 6px" :  "0.5rem 0.75rem")};
  border: 0px;
  margin-right: 30px;
  position: relative;
  border-radius: 0.25rem;
  font-weight: ${(props) => (props.theme.mode <= 500 ? "light" : "600")};
  font-size:${rootStyles.fontSize14};
  color: ${lightMode.black};
  background-color: ${lightMode.lightGreen};
  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }
  & a{
    font-size: inherit;
  }

`;
export const WohnenFreizeitBtn = styled.button `
  padding: ${(props) => (props.theme.mode <= 500 ? "4px 6px" : "6px 12px")};
  border: 0px;
  margin-right: 30px;
  position: relative;
  border-radius: 3px;
  font-weight: ${(props) => (props.theme.mode <= 500 ? "light" : "600")};
  color: ${lightMode.white};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? lightMode.orange : lightMode.btnColor};
`;
// GIW and ABW dorp down window **********************

export const GiwAbwBody = styled.div `
  width: 100%;
  letter-spacing: 0.5px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.backgroundBodyColor
      : lightMode.backgroundOrange};
`;
export const GiwAbwWrapper = styled.div `
  padding: ${(props) =>
    props.theme.mode <= 500 ? "0px 15px 30px 15px" : "1em 2em 2em 2em"};
`;

export const AbwBody = styled.div `
  width: 100%;
  letter-spacing: 0.5px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundBodyColor : lightMode.white};
`;
export const GiwAbwBodyText = styled.p `
  width: 100%;
  padding-bottom: 1%;
`;
export const GiwAbwBodyListTitle = styled.p `
  width: 100%;
  font-weight: bold;
  padding: 2% 0% 0.5% 0%;
`;
// Wohnen and Freizeit Section Ends here******************************************************
// Agent Card  Section Ends here******************************************************
export const AgentCardWrapper = styled.div `
  width: 100%;
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.tagBackgroundColor
      : lightMode.agentCardPurple};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
`;
export const AgentCardContainer = styled.div `
  padding: ${(props) => (props.theme.mode <= 700 ? "15px" : "30px 70px ")};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
`;
export const AgentCardLeftContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap:1.5em;
  min-height: ${(props) => (props.theme.mode <= 700 ? "auto" : "300px ")};
`;
export const AgentCardTitle = styled.p `
  font-weight: bold;
  color: white;
  text-direction: uppercase;
  padding-left: ${(props) => (props.theme.mode <= 500 ? "0px" : "15px")};
`;
export const AgentCardLeftContactImageWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const AgentCardLeftContactContainer = styled.div `
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: space-between;
`;
export const AgentCardLeftContact2PicContainer = styled.div `
  display: flex;
  flex-direction: ${(props) => (props.theme.mode <= 500 ? "column" : "row")};
`;

export const AgentCardLeftContactPic = styled.img `
  max-width: ${(props) => (props.theme.mode <= 400 ? "125px" : " 166px")};

  padding: 0px 20px 0px 0px;
`;
export const AgentCardContactText = styled.p `
  color: white;
  padding: 10px 0px;
`;

export const AgentCardRightContainer = styled.div `
  display: flex;
  gap:1.5em;
  height: fit-content;
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "max-content")};
  flex-direction: ${(props) => (props.theme.mode <= 700 ? "row" : "column")};
  justify-content: space-between;
  align-items: center;
`;
export const AgentCardBrenHolzMapContainer = styled.div `
  display: flex;
  align-self:stretch;
  margin: 0 0 0 auto;
  width: ${(props) => (props.theme.mode <= 940 ? "95%" : "max-content")};
  flex-direction: ${(props) => (props.theme.mode <= 940 ? "row" : "column")};
  justify-content: space-between;
  align-items: center;
`;
export const AgentCardRightContactMap = styled.img `
  max-width: ${(props) => (props.theme.mode <= 400 ? "125px" : " 260px")};
  aspect-ratio:1/1;
  padding: 10px 0px 0px 0px;
  margin:auto;
  border-radius: 50%;
  filter: ${(props) =>
    props.theme.theme === "dark" ? "brightness(0.3)" : "none"};
`;
// Agent Card  Section Ends here******************************************************

//Arbeit Section Starts here******************************************************

export const StyledButtonx = styled.button `
  font-weight: 450;
  padding: 4px 0px;
  border: none;
  border-bottom: 2px solid #522032;
  border-radius: 0;
  background-color: transparent;
  font-family: ${Robot};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  & a {
  }
`;

export const Arbeitsection = styled.div `
  width: 100%;
  letter-spacing: 0.5px;
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.backgroundBodyColor
      : lightMode.backgroundOrange};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
`;
export const ArbeitProjectsWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const ArbeitProjectContainer = styled.div `
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "49%")};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.tagBackgroundColor : "transparent"};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  padding: ${(props) => (props.theme.mode <= 700 ? "0px 0px 15px 0px" : "0")};
  display:flex;
  flex-direction:column;
`;
export const ArbeitImage = styled.img `
  width: 100%;
`;
export const ArbeitProjectTextCon = styled.div `
  width: auto;
  padding: ${(props) => (props.theme.mode <= 700 ? "20px 15px" : "1.5em 2em")};
`;
export const ArbeitProjectProjectTitle = styled.p `
  font-weight:bold;
  font-size:${rootStyles.fontSize24};

  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const ArbeitProjectText = styled.p `
  line-height: ${rootStyles.lineHeight};
`;
export const ArbeitTitleAndBtnCon = styled.div `
  padding-bottom: 12px;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const ArbeitTextSection = styled.div `
  width: 100%;
  letter-spacing: 0.5px;
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundBodyColor : lightMode.white};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
`;
export const ArbeitTextWrapper = styled.div `
  padding: ${(props) => (props.theme.mode <= 700 ? "10px 15px" : "2em")};
  margin: 0 auto;
  width: auto;
`;
export const ArbeitTextTitle = styled.h3 `
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.orange};
  padding: 5px 0px 10px 0px;
`;
export const ArbeitOrangWord = styled.span `
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const ArbeitText = styled.p `
  padding: 5px 0px;
`;
export const ArbeitAngebotSvgWrapper = styled.div `
  padding: ${(props) => (props.theme.mode <= 700 ? "10px 15px" : "2em")};
  margin: 0 auto;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.tagBackgroundColor
      : lightMode.white};
`;
export const ArbeitAngebotTextContainer = styled.div `
  align-self: flex-end;
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "40%")};
`;
export const ArbeitAngebotImg = styled.img `
  margin: ${(props) => (props.theme.mode <= 700 ? "0 0 0 auto" : "0px")};

  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "40%")};
`;
//Arbeit Section Ends here******************************************************
//Kunst Section Starts here******************************************************

export const KunstBody = styled.div `
  width: 100%;
  letter-spacing: 0, 5px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.backgroundBodyColor
      : lightMode.backgroundOrange};
`;
export const KunstImg = styled.img `
  width: 100%;
`;
export const KunstTextContainer = styled.div `
  padding: ${(props) => (props.theme.mode <= 700 ? "20px 15px " : "2em")};
  width: auto;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.tagBackgroundColor
      : lightMode.backgroundOrange};
`;
export const KunstTextArea = styled.div `
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "70%")};
  padding: ${(props) => (props.theme.mode <= 700 ? "0 0 15px 0" : "0px")};
`;

export const KunstTitle = styled.p `
  font-weight: bold;
  font-size: ${rootStyles.fontSize24};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
    padding-bottom:10px;
`;
export const KunstText = styled.p `
  padding: 5px 0px;
`;
export const KunstBtnWrapper = styled.div `
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "max-content")};
  display: ${(props) => (props.theme.mode <= 700 ? "block" : "contents")};
  text-align: end;
  padding: ${(props) => (props.theme.mode <= 700 ? " 0px" : "0 30px 0 0")};
`;
export const KunstBtn = styled.button `
  padding: ${(props) => (props.theme.mode <= 500 ? "4px 6px" : "6px 12px")};
  border: 0px;
  height: fit-content;
  align-self: flex-end;
  position: relative;
  border-radius: 3px;
  font-weight: ${(props) => (props.theme.mode <= 500 ? "light" : "600")};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.white};
  background-color: #a12c3f;
`;
//kunst ends here!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//Ausbildung starts here!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export const AusSteBody = styled.div `
  width: 100%;
  display: grid;
  letter-spacing: 0, 5px;
  line-height: ${rootStyles.lineHeight};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundBodyColor : lightMode.white};
`;

export const AusSteTextContainer = styled.div `
  padding: ${(props) => (props.theme.mode <= 700 ? "20px 15px" : "1em 2em 2em 2em")};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundBodyColor : lightMode.white};
    & button{
      float:right;
    }
`;
export const AusSteTitle = styled.h3 `
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
  padding: ${(props) => (props.theme.mode <= 700 ? "10px 0px" : "10px 0px")};
`;

export const AusSteTextWrapper = styled.div `
  padding: ${(props) => (props.theme.mode <= 700 ? "10px 0px" : "20px 0px")};
`;
export const AusSteBlackTitle = styled.p `
  font-weight: bold;
  padding-bottom: 10px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
`;
export const AusSteText = styled.p `
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
`;
export const AusSteOrangeText = styled.p `
  font-weight: medium;
  padding-bottom: 15px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const AusbildungBtnWrapper = styled.div `
  display: ${(props) => (props.theme.mode <= 700 ? "block" : "block")};
  text-align: end;
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundColor : lightMode.white};
  padding: 0px 10px 10px 0px;
`;
export const AusbildungBtn = styled.button `
  padding: ${(props) => (props.theme.mode <= 500 ? "4px 6px" : "6px 12px")};
  border: 0px;
  height: fit-content;
  align-self: flex-end;
  position: relative;
  border-radius: 3px;
  font-weight: ${(props) => (props.theme.mode <= 500 ? "light" : "600")};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.white};
  background-color: #a12c3f;
`;
//ausbildung ends here!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//offne stellen starts here!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export const OffneStellen = styled.div `
  width: 100%;
  display: grid;
  letter-spacing: 0, 5px;
  line-height: ${rootStyles.lineHeight};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.backgroundBodyColor
      : lightMode.backgroundOrange};
`;

export const SteTextContainer = styled.div `
  padding: ${(props) => (props.theme.mode <= 700 ? "20px 15px" : "2em")};
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.tagBackgroundColor
      : lightMode.backgroundOrange};
`;

export const SteTextWrapper = styled.div `
  padding: ${(props) => (props.theme.mode <= 700 ? "10px 0px" : "20px 0px")};
`;
export const StellenTitle = styled.h3 `
  padding-bottom: 10px;

  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const StellenText = styled.p `
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
`;
export const StellenWordsOrange = styled.span `
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const StellenOrangeText = styled.h4 `
  padding-bottom: 15px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const OffneStellenBtnWrapper = styled.div `
  display: ${(props) => (props.theme.mode <= 700 ? "block" : "block")};
  text-align: end;
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.backgroundColor
      : lightMode.backgroundOrange};
  padding: 0px 10px 10px 0px;
`;
export const OffneStellenBtn = styled.button `
  padding: ${(props) => (props.theme.mode <= 500 ? "4px 6px" : "6px 12px")};
  border: 0px;
  height: fit-content;
  align-self: flex-end;
  position: relative;
  border-radius: 3px;
  font-weight: ${(props) => (props.theme.mode <= 500 ? "light" : "600")};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.white};
  background-color: #a12c3f;
`;

//offne stellen ends here!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Here Datenschutz/impressum Section start here******************

export const DatenshutzImpressumBody = styled.div `
  width: 100%;
  letter-spacing: 0.5px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundBodyColor : lightMode.white};
`;
export const DatenShutzImpressumWrapper = styled.div `
  padding: ${(props) =>
    props.theme.mode <= 500 ? "0px 15px 30px 15px" : "1em 2em"};
  line-height: ${rootStyles.lineHeight};
`;
export const DatenshutzText = styled.p `
  padding: 5px 0px;
`;
export const DatenshutzBoldText = styled.p `
  padding: 10px 0px;
  font-weight: bold;
`;
export const DatenshutzOrangeText = styled.p `
  padding: 10px 0px 0px 0px;
  color: ${lightMode.orange};
`;
export const DatenshutzOrangeLink = styled.div `
  padding: 15px 0px;
  & a {
    color: ${lightMode.orange};
  }
`;
export const SkidList = styled.ul `
  list-style: none;
  padding: 10px 0px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  list-style-position: outside;
  & li {
  }
`;
export const DatenschutzAgentCardTitle = styled.h3 `
  text-direction: uppercase;
  padding: 0px 0px 20px 0px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.white};
`;
export const DatenschutzAgentCardText = styled.p `
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.white};
`;

export const ImpressumFlexContainer = styled.div `
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  padding-top:15px;
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.tagBackgroundColor
      : lightMode.white};
  & div{
    width:${(props) => (props.theme.mode <= 500 ? "100%" : "49%")}); 
    
  }& a {
    color:${lightMode.orange};
  }
`;