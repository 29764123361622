import React from "react";
import {
  AbwBody,
  GiwAbwWrapper,
  WohnenFreizeitProjectTitle,
  GiwAbwBodyText,
  GiwAbwBodyListTitle,
} from "../../styledComponents";
import AgentCard from "../../components/AgentCard.js";
import GiwAgentPic from "../../images/WohnenABWsusanne.png";
import GiwAgentMap from "../../images/kronengasse1.png";
import GiwDropDown from "../../components/wohnen-freizeit/GiwAbwDropDown";
import LightGreenButton from "../../components/shared/Button";

const AbwDropDown = (props) => {
  const giwAgentInfo = {
    agentPic: GiwAgentPic,
    fullName: "Susanne Mattes",
    jobTitle: "",
    tel: "Tel. 0176 46102752",
    email: "E-mail: s.mattes@skid-ggmbh.de",
    address: "Kronengasse 1 88662 Überlingen",
    mapPic: GiwAgentMap,
    googleMaps: "https://goo.gl/maps/o4tCnrXAqt12LnLw8",
  };
  return (
    <>
      <GiwDropDown />
      <AbwBody id="FASection">
        <GiwAbwWrapper>
          <WohnenFreizeitProjectTitle style={{ padding: "30px 0px" }}>
            Flexible Assistenzleistung – FA  (früher: Ambulant Betreutes Wohnen — ABW)
          </WohnenFreizeitProjectTitle>
          <GiwAbwBodyText>
            Die Flexible Assistenzleistung richtet sich an Personen mit einer geistigen und/oder seelischen Behinderung, die individuelle Unterstützung in der Alltagsbewältigung und Lebensführung benötigen, um am Leben in der Gesellschaft teilzuhaben.
          </GiwAbwBodyText>
          <GiwAbwBodyText>
            Die Flexible Assistenzleistung umfasst Leistungen zur Unterstützung einer selbständigen und selbstverantwortlichen Lebensführung.
            <br/>
            Es handelt sich um regelmäßige Unterstützung und Begleitung im persönlichen Lebensumfeld.
            <br/>
            Der Umfang der Leistung richtet sich nach dem individuellen Bedarf des Klienten zur sozialen Teilhabe. 
            <br/>
            Hierzu stehen unterschiedliche Pauschalen zur Verfügung (FA-Flex Richtlinie).
            <br/>
            Ausgangspunkt ist die personenzentrierte Arbeit auf der Basis eines individuellen Assistenzplans, der auf die persönlichen Bedürfnisse und Fähigkeiten jedes einzelnen Klienten abgestimmt ist.
            <br/>
            Die wertschätzende Kommunikation auf Augenhöhe und eine vertrauensvolle Beziehung sind dabei unsere wichtigsten Grundsätze.
            <br/>
            Eine gute Zusammenarbeit mit Angehörigen, gesetzlichen Betreuern und dem Leistungsträger sind ebenso wichtig.
            <br/>
          </GiwAbwBodyText>
          <GiwAbwBodyListTitle>
            Individuelle Assistenzleistungen:
          </GiwAbwBodyListTitle>
          <ul style={{ padding: "0px 0px 15px 15px" }}>
            <li>Gespräche über die persönliche Situation</li>
            <li>Förderung und Entwicklung lebenspraktischer Fähigkeiten</li>
            <li>
              Unterstützung im Wohnbereich, Selbstversorgung, Hygiene, Umgang
              mit Finanzen, tägliche Haushaltsführung
            </li>
            <li>
              Konfliktlösungsmöglichkeiten für Krisen und
              Veränderungssituationen
            </li>
            <li>
              Unterstützung im Kontakt zu medizinischen und sozialen Diensten,
              sowie im Umgang mit Behörden und sonstigen Institutionen
            </li>
            <li>Anregung und Beratung zur Erweiterung des Lebenskreises</li>
            <li>
              Begleitung und Beratung in Fragen der Arbeits- und
              Beschäftigungsmöglichkeiten, sowie in den Bereichen Bildung und
              Freizeit
            </li>
            <li>Einzelwohnen</li>
            <li>Paarwohnen</li>
            <li>Wohngemeinschaften</li>
          </ul>

          <GiwAbwBodyListTitle>Formale Aufnahmekriterien</GiwAbwBodyListTitle>
          <GiwAbwBodyText style={{width:"75%"}}>
            Die Assistenzleistung im Rahmen der Flexiblen Assistenzleistung wird von der Eingliederungshilfe durch den zuständigen Leistungsträger übernommen, sofern die entsprechenden Voraussetzungen erfüllt sind.
          </GiwAbwBodyText>
          <GiwAbwBodyText>
            Dies kann auch über das Persönliche Budget erfolgen.{" "}
          </GiwAbwBodyText>
        </GiwAbwWrapper>
        <AgentCard
          agentPic={giwAgentInfo.agentPic}
          fullName={giwAgentInfo.fullName}
          jobTitle={giwAgentInfo.jobTitle}
          tel={giwAgentInfo.tel}
          email={giwAgentInfo.email}
          address={giwAgentInfo.address}
          mapPic={giwAgentInfo.mapPic}
          googleMaps={giwAgentInfo.googleMaps}
        />
      </AbwBody>
      <LightGreenButton action={props.readMoreOnWohnen} label={'weniger erfahren'}/>
    </>
  );
};

export default AbwDropDown;
