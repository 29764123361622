import React from "react";
import { Link } from "react-router-dom";
import {
    WohnenFreizeitBtnWrapper,
    LightGreenBtn,
    LightGreenLink,
} from "../../styledComponents";


export default function LightGreenButton(props) {

    let buttonText = props.label != null && props.label.length > 0 ? props.label : "mehr erfahren ...";
    let classes = "";
    
    if(props.classes) classes = props.classes;

    if (props.linkTo) {
        return (

            <LightGreenLink>
                <Link to={props.link} target="_blank">
                    {buttonText}
                </Link>
            </LightGreenLink>

        );
    } else if (props.noWrapper) {
        return (

            <LightGreenBtn onClick={props.action} className={"align-self-end " + classes}>
                {buttonText}
            </LightGreenBtn>
        );
    }
    else {

        return (
            <WohnenFreizeitBtnWrapper>
                <LightGreenBtn onClick={props.action} className={classes}>
                    {buttonText}
                </LightGreenBtn>
            </WohnenFreizeitBtnWrapper>
        );
    }

}

