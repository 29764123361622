import React from "react";
import LogoPanner from "./components/logo-panner/LogoPanner";
import AboutUSsection from "./components/about-us/AboutUs.js";
import WohnenFreizeit from "./components/wohnen-freizeit/WohnenFreizeit.js";
import ArbeitSection from "./components/arbeit-projects/ArbeitSection.js";
import KunstSection from "./components/kunst/Kunst.js";
import AusblidungStellen from "./components/ausbildung-stellen/AusbildungStellen";
import Footer from "./components/footer/Footer";

const LandingPage = () => {
  React.useEffect(()=>{
    if(window.location.href.search("sec") !== -1){
        if(document.getElementById("ArbeitSection") !== null ) 
        {
          const target = document.getElementById("ArbeitSection");
          const targetY = target.getBoundingClientRect().y;
          window.scrollTo(0,targetY*1.9)
        }
    }
  },[]);

  return (
    <div>
      <LogoPanner />
      <AboutUSsection />
      <WohnenFreizeit />
      <div id="ArbeitSection">
        <ArbeitSection />
      </div>
      <KunstSection />
      <AusblidungStellen />
      <Footer />
    </div>
  );
}

export default LandingPage;
