import styled from "styled-components";

import {lightMode,darkMode} from "../../styledComponents";


export const JobOfferSection = styled.section `
& p{
    color: ${(props) =>
        props.theme.theme === "dark" ? darkMode.white : lightMode.black};
}& strong{
    color: ${(props) =>
        props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
}`;
export const StellenTitle = styled.h3 `
 margin:1em 0em;
color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const JobOfferBody = styled.div `
padding: 1.5rem 0 0 0rem;
`;
