import firstPic from "../../images/minified/slide1-min.jpg";
import middlePic from "../../images/minified/slide2-min.jpg";
import lastPic from "../../images/minified/slide3-min.jpg";
import "react-slideshow-image/dist/styles.css";
import React from "react";
import { Slide } from "react-slideshow-image";

const slideImages = [firstPic, middlePic, lastPic];

const Slideshow = () => {
  return (
    <Slide
      easing="ease"
      arrows={false}
      duration={4000}
      height={"max-content"}
      style={{ background: "#F6BEA2" }}>
      <div className="each-slide" style={{ margin: "auto", display: "grid" }}>
        <img
          src={slideImages[0]}
          alt="bistro service"
          style={{ width: "100%" }}
        />
      </div>
      <div className="each-slide" style={{ margin: "auto", display: "grid" }}>
        <img
          src={slideImages[1]}
          alt="Eis essen gehen "
          style={{ width: "100%" }}
        />
      </div>
      <div className="each-slide" style={{ margin: "auto", display: "grid" }}>
        <img
          src={slideImages[2]}
          alt="zuhause betreuen"
          style={{ width: "100%" }}
        />
      </div>
    </Slide>
  );
};

export default Slideshow;
