import React from "react";

import { AboutUSTag, AboutUSTagText } from "../../styledComponents";
import {
  CeAchtBody,
  CeAchtImg,
  CeAchtTextContainer,
  CeAchtText,
  CeAchtTextOrange,
} from "./styledComponents";
import pic from "../../images/minified/artsection/GallerieCE8Startseite-min.jpg";

function GalerieCe8() {
  return (
    <CeAchtBody id="KunstCe8">
      <br />
      <AboutUSTag>
        <AboutUSTagText> Galerie Käsering </AboutUSTagText>{" "}
      </AboutUSTag>{" "}
      <br />
      <CeAchtImg src={pic} />
      <CeAchtTextContainer>
        <CeAchtText>
          Jakob-Kessenring-Str. 30, 88662 Überlingen
        </CeAchtText>
        <CeAchtText>
        Weitere Informationen und aktuelle Veranstaltungen finden Sie
          auf&nbsp;
          <CeAchtTextOrange
            href="//www.galerie-kaesering.de"
            rel="noreferrer"
            target="_blank">
            www.galerie-kaesering.de
          </CeAchtTextOrange>{" "}
        </CeAchtText>{" "}
      </CeAchtTextContainer>{" "}
    </CeAchtBody>
  );
}

export default GalerieCe8;
