import React from 'react';
import {
  gql,
  useQuery
} from "@apollo/client";
import JobOffers from "./JobOffers"

import {
  OffneStellen,
  SteTextContainer,
  OffneStellenBtnWrapper,
} from "../../styledComponents";
import LightGreenButton from '../shared/Button';
//--- GraphQl client

const QUERY = gql`
query{
  jobangeboteCollection{
    items{
      name
      description
    }
  }
}
`;
//------------------------


const OffneStellenComponent = (props) => {
  
  function createData(name, description) {
    let i ={ name, description};
    // console.log('new methode has data => ',i);
    return i;
  };
  function NoItemsFound({message = "", itemscount }) {
    if(message === "") message = "Aktuell haben wir keine offenen Stellen zu besetzen.";
    if (itemscount < 1) return (
        <div className="info-card mb-2">
          <p>{message}</p>
        </div>
    );
    return "ccc";
  }
  const {data, errors ,loading}= useQuery(QUERY);
  const list =[createData('loading...','')];
  if(!loading && !errors){
      list.length = 0;
      // console.log(data);
      data.jobangeboteCollection.items.map((e) => list.push(createData(e.name, e.description)));
  };
  
  return (
    <OffneStellen id="OffneStellenSection">
      <SteTextContainer>
        <NoItemsFound itemscount={list.length} />
        <JobOffers x={list}/>
        <OffneStellenBtnWrapper>
          <LightGreenButton noWrapper={true} classes="mr-0" label="weniger erfahren" action={props.func}/>
        </OffneStellenBtnWrapper>{" "}
      </SteTextContainer>{" "}
    </OffneStellen>
  );
};

export default OffneStellenComponent;
