import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

let direction = "right";

function TransitionLeft(props) {
    return <Slide {...props} direction={direction} />;
}

export default function Alert(props){
  const [show,setShow]= React.useState(false);
  const [transition, setTransition] = React.useState(undefined);

  React.useEffect(()=>{
      direction = props.direction;
      renderButton();
      window.addEventListener('scroll', renderButton);
      setTransition(() => TransitionLeft);

  },[props.direction]);
 
  const GoToTop = () => {
    window.scrollTo(0, 0);
  };
  
  const renderButton = () => {
    setShow(window.scrollY > 0);
  }

  return(
    <>
      {show && <Snackbar
        className="scroll-button"
        open={show}
        anchorOrigin={{ vertical:'bottom', horizontal: 'right' }}
        TransitionComponent={transition}
        key={transition ? transition.name : ''}
        onClick={GoToTop}
        action={
          <React.Fragment>
              <i className="fas fa-arrow-up"></i>
          </React.Fragment>
        }
        />}</>
  )
}