import React from "react";
import {
  AgentCardWrapper,
  AgentCardContainer,
  AgentCardLeftContainer,
  AgentCardTitle,
  AgentCardLeftContactImageWrapper,
  AgentCardLeftContactContainer,
  AgentCardLeftContactPic,
  AgentCardContactText,
  AgentCardRightContainer,
  AgentCardRightContactMap,
} from "../styledComponents";

const AgentCard = (props) => {
  return (
    <AgentCardWrapper>
      <AgentCardContainer>
        <AgentCardLeftContainer>
          <AgentCardTitle>Ansprechpartner</AgentCardTitle>
          <AgentCardLeftContactImageWrapper>
            <AgentCardLeftContactPic src={props.agentPic} />
            <AgentCardLeftContactContainer>
              <AgentCardContactText>
                {props.fullName}
                <br/>
                {props.jobTitle}
              </AgentCardContactText>
              <AgentCardContactText>{props.tel}</AgentCardContactText>
              <AgentCardContactText>{props.email}</AgentCardContactText>
            </AgentCardLeftContactContainer>
          </AgentCardLeftContactImageWrapper>
        </AgentCardLeftContainer>

        <AgentCardRightContainer>
          <AgentCardContactText>{props.address}</AgentCardContactText>
          <a href={props.googleMaps} target="blank">
            <AgentCardRightContactMap src={props.mapPic} />
          </a>
        </AgentCardRightContainer>
      </AgentCardContainer>
    </AgentCardWrapper>
  );
};

export default AgentCard;
