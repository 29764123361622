import React from "react";
import {
  AgentCardWrapper,
  GescheaftAgentsCon,
  AgentCardLeftContainer,
  AgentCardLeftContactContainer,
  AgentCardLeftContactPic,
  AgentCardContactText,
  GescheaftAgentsWrapper
} from "./styledComponents";
import marcoPic from "../../images/Marco.png";
import sabinePic from "../../images/Sabine.png";
import klausPic from "../../images/Klaus.png";

const Geschäftsleitung = () => {
  const Agents = [
    {
      image: marcoPic,
      fullName: "Marco Scica",
      jobTitle: "Geschäftsführung",
      tel: "Tel.: 07551 - 937 98 14",
      email: "E-mail: m.scica@skid-ggmbh.de",
      isCEO:true,
    },
    {
      image: sabinePic,
      fullName: "Sabine Wein",
      jobTitle: "Verwaltung/Management/Design",
      tel: "Tel.: 07551 - 937 98 10",
      email: "E-mail: s.wein@skid-ggmbh.de",
      isCEO:true,
    },
    {
      image: klausPic,
      fullName: "Klaus Hold",
      jobTitle: "Bereich Arbeit / Sozialer Dienst",
      tel: "Tel.: 07551 - 937 98 13",
      email: "E-mail: k.hold@skid-ggmbh.de",
      isCEO:false,
    },
  ];




  return (
    <AgentCardWrapper>
     <GescheaftAgentsWrapper>
        <GescheaftAgentsCon>
          {Agents.map((agent) => {
            return(
              <AgentCardLeftContainer id={agent.fullName} key={agent.fullName}>
                <AgentCardLeftContactPic src={agent.image} />{" "}
                <AgentCardLeftContactContainer>
                  <AgentCardContactText> {agent.fullName} </AgentCardContactText>{" "}
                  <AgentCardContactText style={{ padding: "0px 0px 15px 0px" }}>
                    {" "}
                    {agent.jobTitle}{" "}
                  </AgentCardContactText>{" "}
                  <AgentCardContactText> {agent.tel} </AgentCardContactText>{" "}
                  <AgentCardContactText> {agent.email} </AgentCardContactText>{" "}
                </AgentCardLeftContactContainer>{" "}
              </AgentCardLeftContainer>
            )
          })}
        </GescheaftAgentsCon>
      </GescheaftAgentsWrapper>
    </AgentCardWrapper>
  );
};

export default Geschäftsleitung;
