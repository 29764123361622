import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import "./LogoPanner.css";
import logo from "./logo.png";


import {
  BlackLogoText,
  OrangeLogoText,
  LogoImage,
  NavlistWrapper,
  LogoContainer,
  LogoWrapper,
  LogoTextWrapper,
} from "../../styledComponents";
import { ThemeProvider } from "styled-components";
import NavList from "./NavList";
import ArbeitNavList from "./ArbeitNavList";
import MobileNavlist from "./NavListMobile";

function LogoPanner() {
  const wohnenList = {
    listname: "Wohnen & Freizeit",
    children: [
      { text: "Über Wohnen und Freizeit", link: "/#WohnenFreizeit" },
      { text: "Gemeindeintegriertes Wohnen – GIW ", link: "/#GiwSection" },
      {
        text: "Flexible Assistenzleistung – FA",
        link: "/#FASection",
      },
    ],
  };
  const arbeitList = {
    listname: "Arbeit",
    children: [
      { text: "Über Arbeit", link: "#ArbeitSection", target: null },
      { text: "GOLDBACH", link: "/Goldbach", target: "_blank" },
      { text: "SKIDS BIO BISTRO", link: "/Bio-Bistro", target: "_blank" },
      {
        text: "ÜBERLINGER NUDELMANUFAKTUR",
        link: "/Nudel-Manufaktur",
        target: "_blank",
      },
      { text: "NUDELEMMA", link: "/Nudel-Emma", target: "_blank" },
      {
        text: "ÜBERLINGER NUDELLADEN ",
        link: "/Nudel-Laden",
        target: "_blank",
      },
      { text: "BACKMOBIL", link: "/Back-Mboil", target: "_blank" },
    ],
  };

  const kunstList = {
    listname: "Kunst",
    children: [
      { text: "Über Kunst", link: "/#KunstSection" },
      {
        text: "Internationale, künstlerische Zusammenarbeit",
        link: "#KunstTeamWork",
      },
      { text: "Galerie Käsering", link: "/#KunstCe8" },
      { text: '"UNERHÖRT!"', link: "/#KunstUnerhrort" },
    ],
  };
  const stellen_ausbildungList = {
    listname: "Stellen/Ausbildung",
    children: [
      { text: "Ausbildung ", link: "/#AusbildungSection" },
      { text: "Offene Stellen", link: "/#OffneStellenSection" },
    ],
  };

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1300;
  
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <ThemeProvider theme={{ mode: width }}>
      <LogoContainer>
        <LogoWrapper>
          <Link to='/'>
            <LogoImage className="logo-img" src={logo} alt="logo Foto" />
          </Link>
          <LogoTextWrapper>
            {" "}
            <OrangeLogoText>S</OrangeLogoText>
            <BlackLogoText>
              OZIAL<OrangeLogoText>K</OrangeLogoText>ULTURELLE
              <OrangeLogoText>&#32;I</OrangeLogoText>NTEGRATIONS
              <OrangeLogoText>D</OrangeLogoText>IENSTE
            </BlackLogoText>
          </LogoTextWrapper>
        </LogoWrapper>

        {width >= breakpoint ? (
          <NavlistWrapper>
            <NavList
              className="navListW"
              listName={wohnenList.listname}
              children={wohnenList.children}
            />
            <ArbeitNavList
              className="navListW"
              listName={arbeitList.listname}
              children={arbeitList.children}
            />
            <NavList
              className="navListW"
              listName={kunstList.listname}
              children={kunstList.children}
            />
            <NavList
              className="navListW"
              listName={stellen_ausbildungList.listname}
              children={stellen_ausbildungList.children}
            />
          </NavlistWrapper>
        ) : (
          <MobileNavlist />
        )}
      </LogoContainer>
    </ThemeProvider>
  );
}

export default LogoPanner;
