import React from "react";
import {
  gql,
  useQuery
} from "@apollo/client";
import {rootStyles} from "../../../styledComponents.js";
import {
  BodyContainer,
  TagWrapper,
  TagText,
  ImageMain,
  ContentWrapper,
  BrenHolzTextWrapper,
  HeaderTitle,
  ContentTitle,
  OrangeBigText,
} from "./styledCom.js";


import headerPic from "../../../images/minified/goldbachsection/GoldbachHolz-min.jpg";
//here i have got Table from Material-ui
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//here end of imported from Table Material-ui
import brenHolzAgentPic from "../../../images/brenHolz-Lilly.png";
import brenHolzAgent2Pic from "../../../images/brenHolz-Heiko.png";
import brenHolzMapPic from "../../../images/goldBachMapPic.png";
import AgentCard from "./ArbeitAgentCard";

export default function BrenHolzApp() {
 
  const dienstLeistungData = {
    agentPic: [{src:brenHolzAgentPic,fullName:"Liliane Thoma"}, {src:brenHolzAgent2Pic,fullName:"Heiko Kurth"}],
    fullName: "",
    tel: "Tel.: 07551 - 9474062",
    email: "E-mail: brennholz@skid-ggmbh.de",
    address: "Goldbach 40 88662 Überlingen",
    mapPic: brenHolzMapPic,
    googleMaps: "https://goo.gl/maps/BUrVRqjn6uyi2aeP6",
  };
  return (
    <BodyContainer>
      <TagWrapper>
        <TagText>BRENNHOLZVERARBEITUNG</TagText>
      </TagWrapper>
      <br />
      <ImageMain src={headerPic} />
      <ContentWrapper>
        <HeaderTitle>
          Eine Arbeitskette vom Baum bis zum Kunden - von, in, gemeinsam mit der
          Natur
        </HeaderTitle>

        <BrenHolzTextWrapper>
          <ContentTitle>
            Herstellung von Brennholz und weiteren Produkten für den Holzofen
          </ContentTitle>
          <br />
          <p>
            Seit 2010 produzieren wir Brennholz und arbeiten außerdem an vielen
            Produkten rund um das Thema Holz – von Zubehör über kreative und
            künstlerische Holzarbeiten bis hin zu kleineren
            Gartenpflegeaufträgen.<br/> Die vielen unterschiedlichen Tätigkeiten
            und Arbeitsbereiche machen den Brennholzbereich zu einem
            facettenreichen Ort, an dem jeder seinen Platz findet und sich mit
            seinen individuellen Fähigkeiten voll und ganz einbringen kann.{" "}
          </p>
          <br />
          <p>Unsere aktuellen Produkte: </p>
          <br />
          <p>
            Brennholz in den gängigen Maßen von 25 und 33 cm (Preise sind pro
            Ster).
            <br />
            Sondermaße können wir nach Absprache gerne für Sie bereitstellen.
          </p>
          <p>
            Das Holz wird nach zweijähriger Lagerung verkauft. Wir liefern Ihnen
            Ihr Brennholz ofenfertig getrocknet nach Hause.
            <br />
            Die Lieferkosten erfragen Sie bitte bei uns, diese richten sich nach
            der Entfernung. <br />
            Wir machen Ihnen gerne ein persönliches Angebot.
          </p>
          <br />
          <p>
            Unsere Preise beziehen sich auf 1 Ster = 1 Raummeter = 1 m³ gestapeltes Holz. 
          </p>
        </BrenHolzTextWrapper>
        <br />
        <br />

        <BasicTable />

        <p style={{ padding: "3em 0 0 0em" }}>Lieferung bis 10 km kostenlos</p>
        <br />
        <p>Abholung in Goldbach möglich</p>
        <br />
        <p>Lieferung ausserhalb von ÜB: 0,80€ / km</p>
        <br />
        <p style={{ padding: "0em 0 3em 0em" }}>
          Preise sind inklusive Mehrwertsteuer
        </p>
        <OrangeBigText>
          Gerne lassen wir Ihnen ein unverbindliches Angebot zukommen, so dass
          Sie einen warmen, gemütlichen Winter vor dem heimischen Ofen genießen
          können.
        </OrangeBigText>
      </ContentWrapper>

      <AgentCard
        agentPic={dienstLeistungData.agentPic}
        agentPic2={dienstLeistungData.agentPic2}
        fullName={dienstLeistungData.fullName}
        mobile={dienstLeistungData.mobile}
        tel={dienstLeistungData.tel}
        email={dienstLeistungData.email}
        address={dienstLeistungData.address}
        mapPic={dienstLeistungData.mapPic}
        googleMaps={dienstLeistungData.googleMaps}
      />
    </BodyContainer>
  );
}



const useStyles = makeStyles({
  table: {
    maxWidth: 660,
    margin: "0 auto ",
    color: "inherit;",
  }
});
const StyledTh = withStyles({
  root: {
    borderRadius: 0,
    border: `solid 3px ${rootStyles.orange};`,
    height: 48,
    padding: "0 ",
    boxShadow: "none",
    textAlign: "center",
    color: "inherit;",
  },
})(TableCell);
const StyledTableContainer = withStyles({
  root: {
    borderRadius: 0,
    border: 0,
    boxShadow: "none",
    backgroundColor: "inherit;",
    color: "inherit;",
  },
})(TableContainer);

//--- GraphQl client


const QUERY= gql`
query{
  woodObjectCollection(order:sys_publishedAt_ASC){
    items{
      size
      hardWood
      softWood
    }
  }
}`
function createData(a, b, c) {
  // console.log(c," softWood is ");
  return { size:a, hardWood:b, softWood:c};
}
//==========================

function BasicTable() {
  const classes = useStyles();
  const {data, errors ,loading}= useQuery(QUERY);
  const rows = [ createData('Loading...', 'Loading...', 'Loading...')];
   if(!loading && !errors){
    rows.length = 0;
      data.woodObjectCollection.items.map((e) => rows.push(createData(e.size, e.hardWood, e.softWood)));
   }

  return (
    <StyledTableContainer component={Paper}>
      <p style={{ padding: "0px 0px 10px 0px" }}>Preise pro Ster:</p>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTh>Größe</StyledTh>
            <StyledTh align="right">Hartholz</StyledTh>
            <StyledTh align="right">Nadelholz</StyledTh>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,index) => (
            <TableRow key={index}>
              <StyledTh component="th" scope="row">
                {row.size}
              </StyledTh>
              <StyledTh align="right">{row.hardWood}</StyledTh>
              <StyledTh align="right">{row.softWood}</StyledTh>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
