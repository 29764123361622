import React from "react";
import {
  BodyContainer,
  TagWrapper,
  TagText,
  ImageMain,
  NudelLadenContentWrapper,
  TextWrapper,
  NudelLadenLeftTextContainer,
  TextRightContainer,
  ContentTitle,
  OrangeLink,
  SkidList,
  OrangeBigText,
  ShoppingCart,
  SkidListContainer,BioBistroOrangeText
} from "./goldBachSeite/styledCom.js";
import LogoPanner from "../arbeit-projects/HeaderLogoPanner";
import nudelLadenHeaderPic from "../../images/minified/Nudelladen-min.jpg";
import nudelEmmaShoopingCart from "../../images/nudelnladenShoppingCart.svg";
import AgentCard from "./goldBachSeite/ArbeitAgentCard";
import { nudelLadenAgentData } from "./goldBachSeite/projectsData.js";

import Footer from "../footer/Footer.js";

function index() {
  return (
    <BodyContainer>
      <LogoPanner />
      <TagWrapper>
        <TagText> NUDELLADEN </TagText>{" "}
      </TagWrapper>{" "}
      <br />
      <ImageMain src={nudelLadenHeaderPic} />{" "}
      <NudelLadenContentWrapper>
        <TextWrapper style={{ lineHeight: "25px", paddingTop: "20px" }}>
          <NudelLadenLeftTextContainer>
            <ContentTitle style={{ paddingBottom: "15px" }}>
              Unser Angebot
            </ContentTitle>
            <SkidList>
              <SkidListContainer>
                {" "}
                <li>
                  Bio-Nudeln aus eigener Manufaktur
                </li>
                <li>Käse</li> <li>Weine</li>
                <li>Antipasti</li> 
              </SkidListContainer>
              <SkidListContainer>
                <li>Pesto & Saucen</li>
                <li>Gewürze & Öle</li>
                <li>Feines aus der Region</li>
                <li>
                  Unverpackt! Nudeln, Nüsse, Müsli Trockenfrüchte, usw.
                </li>
                
              </SkidListContainer>
            </SkidList>
          </NudelLadenLeftTextContainer>{" "}
          <TextRightContainer>
            <ContentTitle style={{ paddingBottom: "10px" }}>
              WISSENSWERTES
            </ContentTitle>
            <SkidList>
              <li>
                <b className="pb-1">Standort:</b> <br />
                <p className="pt-05">Christophstraße 15, 88662 Überlingen 3 Gehminuten vom Parkhaus
                West</p>
              </li>{" "}
              <li>
                <b>Öffnungszeiten:</b>
               <div className="pt-05">
                  <div className="d-flex justify-content-between">
                    <BioBistroOrangeText>Montag, Dienstag, Donnerstag, Freitag:</BioBistroOrangeText>
                    <BioBistroOrangeText>09:30 – 18:00 Uhr</BioBistroOrangeText>
                  </div>
                  <div className="d-flex justify-content-between">
                    <BioBistroOrangeText>Mittwoch + Samstag:</BioBistroOrangeText>
                    <BioBistroOrangeText>09:30 – 14:00 Uhr</BioBistroOrangeText>
                  </div>
                </div>
              </li>
              <li>
                Besuchen Sie auch unseren Onlineshop
                <br />
                <OrangeLink className="pt-05"
                  href="https://nudelladen.biodeliver.de/"
                  target="blank">
                  www.nudelladen.biodeliver.de
                </OrangeLink>
              </li>
            </SkidList>
          </TextRightContainer>{" "}
        </TextWrapper>{" "}
        <OrangeBigText>
          Gerne gestalten wir Ihnen einen individuellen Geschenkkorb
        </OrangeBigText>
      </NudelLadenContentWrapper>
      <div style={{ textAlign: "center" }}>
        <ShoppingCart src={nudelEmmaShoopingCart} />
      </div>
      <AgentCard
        agentPic={nudelLadenAgentData.agentPic}
        fullName={nudelLadenAgentData.fullName}
        tel={nudelLadenAgentData.tel}
        email={nudelLadenAgentData.email}
        address={nudelLadenAgentData.address}
        mapPic={nudelLadenAgentData.mapPic}
        googleMaps={nudelLadenAgentData.googleMaps}
      />{" "}
      <Footer />
    </BodyContainer>
  );
}

export default index;
