import React from "react";
import {
  BodyContainer,
  TagWrapper,
  TagText,
  ImageMain,
  ContentWrapper,
  TextWrapper,SkidUListContainer,
  NudelManuTextContainer,
  NudelManufakturNudelPicCon,
  HeaderTitle,NudelManufakturListWrapper,NudelManufakturNudelRightPicCon,
  SkidList,NudelManuProductPic,
  ContentTitle,
  OrangeLink,
} from "./goldBachSeite/styledCom.js";
import LogoPanner from "../arbeit-projects/HeaderLogoPanner";
import nudelManufakturHeaderPic from "../../images/minified/Nudelmanufaktur-min.jpg";
import AgentCard from "./goldBachSeite/ArbeitAgentCard";
import { nudelManufakturData } from "./goldBachSeite/projectsData.js";

import Footer from "../footer/Footer.js";

function index() {
  return (
    <BodyContainer>
      <LogoPanner />
      <TagWrapper>
        <TagText>ÜBERLINGER NUDELMANUFAKTUR</TagText>{" "}
      </TagWrapper>{" "}
      <br />
      <ImageMain src={nudelManufakturHeaderPic} />{" "}
      <ContentWrapper>
        <HeaderTitle>Sozial, lokal und lecker</HeaderTitle>{" "}
        <TextWrapper style={{ lineHeight: "25px", paddingTop: "20px" }}>
          <NudelManuTextContainer>
            
           <NudelManufakturListWrapper> 
            <SkidUListContainer>
             <SkidList >  
                   <ContentTitle style={{ paddingBottom: "15px" }}>
              {" "}
              Wir bieten Ihnen{" "}
            </ContentTitle>
              <li>
              Hausgemachte BIO Nudeln aus eigener Produktion
              </li>
             
              <li style={{width:"75%"}}>Entdecken Sie die Seewogen, Schraublinge, Locken, Bandnudeln, …</li>
              <li>Frisch oder getrocknet, als Steinpilz-, rote Bete-, Spinatnudeln oder einfach "natur", mit oder ohne Ei 
 
              </li>{" "}
              
            </SkidList></SkidUListContainer>
          <NudelManufakturNudelPicCon> <NudelManuProductPic style={{left:"-50%"}} src={nudelManufakturData.nudelPic1}></NudelManuProductPic>
              <NudelManuProductPic  src={nudelManufakturData.nudelPic4}></NudelManuProductPic>
              
             </NudelManufakturNudelPicCon> 
            </NudelManufakturListWrapper>
            <div  style={{textAlign:"center", width:"50%",paddingTop:"15px"}}><NudelManuProductPic src={nudelManufakturData.nudelPic3}></NudelManuProductPic></div>
          </NudelManuTextContainer>{" "}
          <NudelManuTextContainer>
            
            <NudelManufakturListWrapper  style={{margin:"0 0 0 auto", height:"75%"}}>
            <SkidList >
              <ContentTitle style={{ paddingBottom: "10px" }}>
              {" "}
              WISSENSWERTES 
            </ContentTitle>
              <li>
              Anzahl Mitarbeiter 16
              </li>
              <li>
                Website:&nbsp;
                <OrangeLink href="https://www.ueberlinger-nudelmanufaktur.de/" target="blank">
                 www.ueberlinger-nudelmanufaktur.de
                </OrangeLink>
              </li>{" "}
              <li>Bio zertifiziert nach DE - ÖKO - 007 </li>
             
            </SkidList>
            <NudelManufakturNudelRightPicCon>
            <NudelManuProductPic style={{maxWidth:"125px"}} src={nudelManufakturData.nudelPic2}></NudelManuProductPic>
                
             <NudelManuProductPic src={nudelManufakturData.nudelPic5}></NudelManuProductPic>
             </NudelManufakturNudelRightPicCon>
            </NudelManufakturListWrapper>
          </NudelManuTextContainer>{" "}
        </TextWrapper>{" "}
      </ContentWrapper>{" "}
      <AgentCard
        agentPic={nudelManufakturData.agentPic}
        fullName={nudelManufakturData.fullName}
        tel={nudelManufakturData.tel}
        email={nudelManufakturData.email}
        address={nudelManufakturData.address}
        mapPic={nudelManufakturData.mapPic}
        googleMaps={nudelManufakturData.googleMaps}
      />{" "}
      <Footer />
    </BodyContainer>
  );
}

export default index;
