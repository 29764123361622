import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloProvider, ApolloClient, InMemoryCache}from "@apollo/client";
require('dotenv').config();

const client = new ApolloClient({
    cache: new InMemoryCache(),
  uri:`https://graphql.contentful.com/content/v1/spaces/mf9zkbzhq0s9`,
  headers:{
    'Authorization': `Bearer 98fea08x6CIeehBrdFrBrjVN6nJTG5G_sFCdBELoJ24`,
  },
  })

ReactDOM.render(
    <React.StrictMode >
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();