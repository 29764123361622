import styled from "styled-components";
import {rootStyles,lightMode,darkMode} from "../../styledComponents";


export const FooterContainer = styled.div`
  background-color: ${rootStyles.footerPurple};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.white};
`;
export const FooterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 450;
  padding: ${(props) => (props.theme.mode <= 700 ? "20px 15px " : "1em 2em")};
`;
export const FooterLinksCon = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.theme.mode <= 365 ? "45%" : "50%")};
`;
export const FooterTextCon = styled.div`
  width: ${(props) => (props.theme.mode <= 365 ? "50%" : "50%")};
`;
export const FooterLinks = styled.a`
  text-decoration: none;
`;
export const FooterText = styled.p`
  width: fit-content;
  margin: 0 0 0 auto;
  text-align: ${(props) => (props.theme.mode <= 852 ? "center" : "unset")};
`;
