import styled from "styled-components";
import {lightMode,darkMode}from "../../../styledComponents";



export const BodyContainer = styled.div `
  width: 100%;
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundBodyColor : lightMode.white};
`;

export const TagWrapper = styled.div `
  width: max-content;
  justify-content: center;
  display: flex;
  margin-top:2em;
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.purple : lightMode.purple};
`;

export const TagText = styled.p `
  color: white;
  padding: 10px 20px;
  text-align: center;
  align-self: center;
  font-weight: bold;
`;

export const ImageMain = styled.img `
  width: 100%;
`;
export const ContentWrapper = styled.div `
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundColor : "transparent"};
  padding: ${(props) => (props.theme.mode <= 700 ? "20px 15px" : "2em")};
`;
export const TextWrapper = styled.div `
  display: flex;
  padding: ${(props) => (props.theme.mode <= 700 ? "10px 0px" : "15px 0px")};
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const TextContainer = styled.div `
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "49%")};
  display: block;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundColor : lightMode.white};
`;
export const TextRightContainer = styled.div `
  width: ${(props) => (props.theme.mode <= 700 ? "100%" : "fit-content")};
  display: block;
  padding: ${(props) =>
    props.theme.mode <= 967 ? "20px 0px 0px 0px " : "0px"};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundColor : lightMode.white};
`;
export const HeaderTitle = styled.h3 `
  margin: 0 auto;
  width: fit-content;
  padding: ${(props) =>
    props.theme.mode <= 700 ? "0px 0px 7px 0px" : "0px 0px 15px 0px"};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const ContentTitle = styled.p `
  padding:1em 0em;
  font-weight: bold;
`;

export const BrenHolzTextWrapper = styled.div `
  padding: ${(props) => (props.theme.mode <= 700 ? "10px 0px" : "15px 0px")};
`;
export const OrangeBigText = styled.p `
  font-weight: 450;
  font-size:1.5em;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const OrangeLink = styled.a `
  text-decoration: none;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const NudelLadenContentWrapper = styled.div `
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundColor : "transparent"};
  padding: ${(props) =>
    props.theme.mode <= 700 ? "20px 15px 0px 15px" : "1em 2em"};
`;
export const SkidList = styled.ul `
  list-style:none;
  display:flex;
  flex-direction:column;
  gap:1rem;
  color:${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  list-style-position: inside;
`;
export const NudelLadenLeftTextContainer = styled.div `
  width: ${(props) => (props.theme.mode <= 967 ? "100%" : "49%")};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundColor : lightMode.white};
`;
export const SkidListContainer = styled.div `
  display:flex;
  flex-direction:column;
  gap:0.25em;
  width: ${(props) => (props.theme.mode <= 500 ? "75%" : "50%")};
`;
export const ShoppingCart = styled.img `
  width: 50%;
  position: relative;
  bottom: -8px;
`;
export const OrangeText = styled.span `
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const BackMobilProsUlWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
  width: ${(props) => (props.theme.mode <= 900 ? "100%" : "30%")};
`;

export const NudelManufakturListWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
  flex-wrap:${(props) => (props.theme.mode <= 400 ? "wrap" : "nowrap")};
  width:${(props) => (props.theme.mode <= 1000 ? "100%" : "80%")};
`;
export const SkidUListContainer = styled.div `
padding-right:${(props) => (props.theme.mode <= 400 ? "0px" : "86px")};
  width:${(props) => (props.theme.mode <= 1000 ? "100%" : "80%")};
`;
export const NudelManuTextContainer = styled.div `
  width: ${(props) => (props.theme.mode <= 900 ? "95%" : "49%")};
  display: block;
  margin: 0 auto;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundColor : lightMode.white};
`;
export const NudelManufakturNudelPicCon= styled.div `
  display: flex;
  justify-content: space-between;
  flex-direction:column;
  width:${(props) => (props.theme.mode <= 400 ? "100%" : "auto")};
  align-items: flex-end;
`;
export const NudelManufakturNudelRightPicCon= styled.div `
  display: flex;
  justify-content: space-between;
  height:${(props) => (props.theme.mode <= 900 ? "120%" : "75%")};
  flex-direction:${(props) => (props.theme.mode <= 400 ? "row" : "column")};
  width:${(props) => (props.theme.mode <= 400 ? "100%" : "auto")};
  align-items:${(props) => (props.theme.mode <= 400 ? " baseline" : "flex-end")}; 
`;
export const NudelManuProductPic = styled.img `
  max-width: 115px;
  position: relative;
  left: 0px;
`;

export const BioBistroOrangeText = styled.span `
 color:${(props) =>
  props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
