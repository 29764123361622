import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import {
  AboutUSTag,
  AboutUSTagText,
  AusSteBody,
  AusSteTextContainer,
  AusSteTitle,
  AusSteTextWrapper,
  AusSteBlackTitle,
  AusSteText,
  AusSteOrangeText
} from "../../styledComponents";
import OfnneStellenComment from "./OffneStellen";
import useWindowSize from "../../components/useWindowSize";
import LightGreenButton from "../shared/Button";

const AusblidungStellen = () => {
  const [moreOnJobOffers, setMoreOnJobOffers] = useState(false);

  const toggelReadMore = useCallback(() => {
    if(moreOnJobOffers){
      window.location.hash = "#OffneStellenSection" ? window.location.hash='':null;
    };
    setMoreOnJobOffers(!moreOnJobOffers);
  }, [moreOnJobOffers]);

  const size = useWindowSize();
  useEffect(() => {
    document
      .getElementById("Stellen/Ausbildung")
      .addEventListener("click", toggelReadMore);
  }, [toggelReadMore, size.width]);

  const ausSteData = [
    {
      title: "Ausbildungsbeschreibung:",
      text: [
        " Die Ausbildung erfolgt im Wechsel von Theorie und Praxis. Zentrale Ziele sind die größtmögliche Selbstbestimmung von Menschen mit Behinderungen, ihre Teilhabe am gesellschaftlichen Leben, die Entfaltung ihrer individuellen Fähigkeiten und die Führung eines subjektiv sinnerfüllten Lebens.",
        "Heilerziehungspflege bedeutet persönliche Unterstützung und Assistenz im Sinne einer Verbesserung der Lebensqualität des Menschen mit Behinderung.",
      ],
    },
    {
      title: "Ausbildungsstruktur:",
      text: [
        "Die Gesamtausbildung dauert 3 Jahre. Sie arbeiten in diesem Zeitraum in einem sozial- und heilpädagogischen Handlungsfeld, in stationär-dezentralen Wohngemeinschaften mit 5-10 Personen und einem hausinternen Wahlbereich.",
        "Ein Pflegepraktikum von 4 Wochen können Sie hausextern wählen.",
        "An zwei Tagen in der Woche sind Sie bei den Camphill-Ausbildungen gGmbH in der Fachschule.",
      ],
    },
    {
      title: "Anleitungsgespräche:",
      text: [
        "Sie bekommen eine qualifizierte Fachpraxisanleitung, die sich 1x wöchentlich fest terminiert mit Ihnen zu einem Anleitungsgespräch trifft.",
      ],
    },
    {
      title: "Fachpraxistreffen:",
      text: [
        " In einem intern angebotenen Fachpraxistreffen, das 1x monatlich mit allen HEP-SchülerInnen stattfindet, bekommen Sie die Möglichkeit, übergeordnete Themen der Einrichtung, sowie schulische Anforderungen gemeinsam zu bearbeiten.",
      ],
    },
    {
      title: "Teilhabe an Organisationsprozessen:",
      text: [
        "Es besteht für alle HEP-SchülerInnen die Möglichkeit, an diversen Teambesprechungen teilzunehmen und sich einzubringen.","Auch werden die Schüler zu Klausurtagen und sämtlichen Angeboten der Einrichtung eingeladen."
      ],
    },
  ];
  return (
    <>
      <AusSteBody id="AusbildungSection">
        <br />
        <AboutUSTag>
          <AboutUSTagText> Ausbildung / Offene Stellen </AboutUSTagText>{" "}
        </AboutUSTag>{" "}
        <br />
        <AusSteTextContainer>
          <AusSteTitle>
            Heilerziehungspflegerin / Heilerziehungspfleger{" "}
          </AusSteTitle>{" "}
          {ausSteData.map((item) => (
            <AusSteTextWrapper key={item.title+Math.floor(Math.random() * 1000)}>
              <AusSteBlackTitle> {item.title} </AusSteBlackTitle>{" "}
              {item.text.map((x) => (
                <AusSteText key={"ausbildung-text-p"+Math.floor(Math.random() * 1000)}> {x} </AusSteText>
              ))}{" "}
            </AusSteTextWrapper>
          ))}{" "}
          <AusSteTextWrapper>
            <AusSteOrangeText>
              Wir freuen uns über jede interessante Bewerbung von engagierten
              Menschen mit fachlichen und sozialen Kompetenzen.{" "}
            </AusSteOrangeText>{" "}
            <AusSteText style={{ paddingBottom: "15px" }}>
              Senden Sie Ihre Bewerbungsunterlagen an:
            </AusSteText>{" "}
            <AusSteText>
              SKID gGmbH <br />
              Kronengasse 1 <br />
              88662 Überlingen <br />
              www.skid - ggmbh.de{" "}
            </AusSteText>{" "}
          </AusSteTextWrapper>{" "}
          {moreOnJobOffers === false && (
            <LightGreenButton classes="mr-0" noWrapper={true}action={toggelReadMore}/>
          )}{" "}
        </AusSteTextContainer>{" "}
      </AusSteBody>{" "}
      {moreOnJobOffers === true && (
        <OfnneStellenComment func={toggelReadMore} />
      )}{" "}
    </>
  );
};

export default AusblidungStellen;
