import React from "react";
import {
  AgentCardWrapper,
  AgentCardContainer,
  AgentCardLeftContainer,
  AgentCardTitle,
  AgentCardLeftContactImageWrapper,
  AgentCardLeftContact2PicContainer,
  AgentCardLeftContactContainer,
  AgentCardLeftContactPic,
  AgentCardContactText,
  AgentCardBrenHolzMapContainer,
  AgentCardRightContactMap,
} from "../../../styledComponents";

const AgentCard = (props) => {
  // console.log(" Mobile is ", props.mobile);
  return (
    <AgentCardWrapper>
      <AgentCardContainer>
        <AgentCardLeftContainer>
          <AgentCardTitle> Ansprechpartner </AgentCardTitle>{" "}
          <AgentCardLeftContactImageWrapper className={props.agentPic && props.agentPic.length > 1 ? "flex-column align-items-start" : "" } >
            <AgentCardLeftContact2PicContainer>
              {props.agentPic && props.agentPic.length <2 ? props.agentPic.map((pic) => (
                <AgentCardLeftContactPic key={pic+Math.floor(Math.random() * 1000)} src={pic} />
              )) : <div className="d-flex justify-content-center gap-4"> {props.agentPic.map((pic) => (
                          <div className="d-flex align-items-center flex-column" key={pic.src+Math.floor(Math.random() * 1000)}>
                            <AgentCardLeftContactPic src={pic.src}  className="p-0"/>
                            <AgentCardContactText className="mt-n-1-75"> {pic.fullName} </AgentCardContactText>
                          </div>
                        ))}
                    </div>}
            </AgentCardLeftContact2PicContainer>
            <AgentCardLeftContactContainer className={props.agentPic && props.agentPic.length > 1 ? "pl-1 mt-2" : ""}>
             {props.fullName && props.fullName.length > 0 ?  <AgentCardContactText> {props.fullName} </AgentCardContactText>:null}
              {props.mobile && props.mobile.length > 0 ? <AgentCardContactText className="mb-1"> {props.mobile} </AgentCardContactText>:null}
              <AgentCardContactText className="mb-1"> {props.tel} </AgentCardContactText>{" "}
              <AgentCardContactText> {props.email} </AgentCardContactText>{" "}
            </AgentCardLeftContactContainer>{" "}
          </AgentCardLeftContactImageWrapper>{" "}
        </AgentCardLeftContainer>
        <AgentCardBrenHolzMapContainer className="gap-1-5">
          <AgentCardContactText className="p-0"> {props.address} </AgentCardContactText>{" "}
          <a href={props.googleMaps} target="blank">
            <AgentCardRightContactMap src={props.mapPic} className="p-0"/>{" "}
          </a>{" "}
        </AgentCardBrenHolzMapContainer>{" "}
      </AgentCardContainer>{" "}
    </AgentCardWrapper>
  );
};

export default AgentCard;
