import styled from "styled-components";
import { lightMode,darkMode } from "../../styledComponents";

export const AgentCardWrapper = styled.div`
 width:100%;
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.backgroundColor
      : lightMode.agentCardPurple};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
`;
export const AgentCardContainer = styled.div`
padding: ${(props) =>
  props.theme.mode <= 700 ? "15px" : "30px 70px "};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const AgentCardLeftContainer = styled.div`
  display: flex;
  align-items: ${(props) => (props.theme.mode <= 700 ? "center" : "unset")};
  flex-direction: ${(props) => (props.theme.mode <= 700 ? "row" : "column")};
  justify-content: space-between;
`;
export const AgentCardTitle = styled.p`
  font-weight: bold;
  color: white;
  text-direction: uppercase;
  padding-left: ${(props) => (props.theme.mode <= 500 ? "0px" : "15px")};
`;
export const GescheaftCardTitle = styled.p`
  font-weight: bold;
  color: white;
  text-direction: uppercase;
`;
export const GescheaftAgentsWrapper = styled.div`
padding: ${(props) =>
  props.theme.mode <= 500 ? "15px" : "0em 2em 2em 2em"};
  display:flex;
  justify-content:space-between;
  align-items:center;
  flex-direction:row;
  flex-wrap:wrap;

`;
export const GescheaftAgentsCon= styled.div`

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items:center;
  width:100%;

`;
export const AgentCardLeftContactContainer = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: space-between;
  padding: ${(props) => (props.theme.mode <= 500 ? "15px 0px" : "0 0 0 1.0em")};
`;
export const AgentCardLeftContactPic = styled.img`
  max-width: 166px;
  height: ${(props) => (props.theme.mode <= 500 ? "125px" : "auto")};
  padding: 0px 20px 0px 0px;
`;
export const AgentCardContactText = styled.p`
  color: white;
  font-size: ${(props) => (props.theme.mode <= 500 ? "12px" : "20px")};
`;
