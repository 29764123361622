import React from "react";
import { Link } from "react-router-dom";
import {
  FooterContainer,
  FooterWrapper,
  FooterLinksCon,
  FooterTextCon,
  FooterText,
} from "./styledComponents";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterLinksCon>
          <Link to="/Datenshutz" target="_blank">
            Datenschutz |
          </Link>

          <Link to="/Datenshutz?sec=impressum" target="_blank">
            {" "}
            Impressum
          </Link>
        </FooterLinksCon>
        <FooterTextCon>
          <FooterText>
            © 2012 - {new Date().getFullYear()} Skid gGmbH | Kronengasse 1 | 88662 Überlingen
          </FooterText>
        </FooterTextCon>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
