import React from "react";
import {
  BodyContainer,
  TagWrapper,
  TagText,
  ImageMain,
  ContentWrapper,
  HeaderTitle,
  TextWrapper,
  TextContainer,
  SkidList,
  TextRightContainer,
} from "./styledCom.js";
import AgentCard from "./ArbeitAgentCard";

function index(props) {
  return (
    <BodyContainer>
      <TagWrapper>
        <TagText>{props.tagTitle}</TagText>
      </TagWrapper>
      <br />
      <ImageMain src={props.headerPic} />
      <ContentWrapper>
        {props.orangeText.length >= 1 && (
          <HeaderTitle>{props.orangeText}</HeaderTitle>
        )}
        <TextWrapper>
          <TextContainer>
            <SkidList className="test">
              {props.linksText.map((x) => (
                <li key={props.headerPic+Math.floor(Math.random() * 10000)}> {x}</li>
              ))}
            </SkidList>
          </TextContainer>
          <TextRightContainer>
            <SkidList>
              {props.rechtsText.map((y) => (
                <li key={props.headerPic+Math.floor(Math.random() * 10000)}> {y}</li>
              ))}
            </SkidList>
          </TextRightContainer>
        </TextWrapper>
      </ContentWrapper>
      <AgentCard
        agentPic={props.agentPic}
        fullName={props.fullName}
        mobile={props.mobile}
        tel={props.tel}
        email={props.email}
        address={props.address}
        mapPic={props.mapPic}
        googleMaps={props.googleMaps}
      />
    </BodyContainer>
  );
}

export default index;
