import styled from "styled-components";
import { rootStyles, lightMode,darkMode } from "../../styledComponents";

export const KunstInternationalBody = styled.div`
  width: 100%;
  letter-spacing: 0, 5px;
  line-height: ${rootStyles.lineHeight};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundBodyColor : lightMode.white};
`;
export const KunstInternationalImg = styled.img`
  width: 100%;
`;
export const KunstInternationalTextContiner = styled.div`
  padding: ${(props) => (props.theme.mode <= 700 ? "20px 15px" : "2em")};
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.tagBackgroundColor
      : lightMode.white};
`;

export const KunstInternationalTitle = styled.p`
  font-weight: bold;
  font-size: ${rootStyles.fontSize24};
  padding-bottom:5px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const KunstInternationalText = styled.p`
  padding: 5px 0px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
`;
export const BlackInternationalText = styled.p`
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
`;
export const KunstInternationalTextOrange = styled.p`
  display: flex;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const KunstBtnWrapper = styled.div`
  display: ${(props) => (props.theme.mode <= 700 ? "block" : "block")};
  text-align: end;

  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundColor : lightMode.white};
  padding: 0px 30px 10px 0px;
`;
export const KunstBtn = styled.button`
  padding: ${(props) => (props.theme.mode <= 500 ? "4px 6px" : "6px 12px")};
  border: 0px;
  height: fit-content;
  align-self: flex-end;
  position: relative;
  border-radius: 3px;
  font-weight: ${(props) => (props.theme.mode <= 500 ? "light" : rootStyles.fontWeight600)};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.white};
  background-color: #a12c3f;
`;

export const CeAchtBody = styled.div`
  width: 100%;
  letter-spacing: 0, 5px;
  line-height: ${rootStyles.lineHeight};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.backgroundBodyColor
      : lightMode.backgroundOrange};
`;
export const CeAchtImg = styled.img`
  width: 100%;
`;
export const CeAchtTextContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.theme.mode <= 700 ? "20px 15px" : "2em")};
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.tagBackgroundColor
      : lightMode.backgroundOrange};
`;

export const CeAchtText = styled.p`
  padding: 5px 0px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
`;

export const CeAchtTextOrange = styled.a`
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;

export const UnerheortBody = styled.div`
  width: 100%;
  display: grid;
  letter-spacing: 0, 5px;
  line-height: ${rootStyles.lineHeight};
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
  background-color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.backgroundBodyColor : lightMode.white};
`;
export const UnerheortImg = styled.img`
  width: 100%;
`;
export const UnerheortTextContainer = styled.div`

  padding: ${(props) => (props.theme.mode <= 700 ? "20px 15px" : "1em 2em 0em 2em")};
  background-color: ${(props) =>
    props.theme.theme === "dark"
      ? darkMode.tagBackgroundColor
      : lightMode.white};
`;
export const UnerheortTitle = styled.p`
  font-weight: bold;
  font-size: ${rootStyles.fontSize24};
  padding-bottom:1em;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;

export const UnerheortText = styled.p`
  padding: 5px 0px;
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.white : lightMode.black};
`;
export const UnerheortTextOrange = styled.a`
  color: ${(props) =>
    props.theme.theme === "dark" ? darkMode.orange : lightMode.orange};
`;
export const UnerheortTextLightGreen = styled.a`
  background-color: ${lightMode.lightGreen};
  display:block;
  padding: ${(props) => (props.theme.mode <= 500 ? "4px 6px" :  "0.5rem 0.75rem")};
  border-radius:0.25rem;
`;