import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import {rootStyles, NavlistItemWrapper, NavlistItemText } from "../../styledComponents";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #522032",
    backgroundColor: "#522030",
    color: "white",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#522032",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary,": {
        color: "",
      },
    },
    "&:hover":{
      backgroundColor: rootStyles.orange,
    }
  },
}))(MenuItem);

const StyledListItemText = withStyles((theme) => ({
  root: {
    "& .MuiTypography-body1":{
      fontSize: rootStyles.fontSize14,
      fontWeight: rootStyles.fontWeight600,
      fontFamily:"inherit"
    }
  },
}))(ListItemText);

const StyledButton = withStyles((theme) => ({
  root: {
    padding: "0",
    borderBottom: "2px solid "+rootStyles.orange,
    borderRadius: "0",
    minWidth: "47px",
    color: "inherit",
    fontSize: rootStyles.fontSize14,
    fontWeight: rootStyles.fontWeight600,
    "&:hover, &:active":{
      color:rootStyles.orange,
      backgroundColor:"transparent"
    },
  },
}))(Button);

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <NavlistItemWrapper>
      <NavlistItemText>
        <StyledButton
          id={props.listName}
          aria-controls="simple-menu-arbeit"
          aria-haspopup="true"
          onClick={handleClick}>
          {props.listName}
        </StyledButton>
      </NavlistItemText>
      <StyledMenu
        id="simple-menu-arbeit"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {props.children.map((child) =>
          child.target === null ? (
            <StyledMenuItem key={child.text}>
              <a href={child.link}>
                <StyledListItemText onClick={handleClose}>{child.text}</StyledListItemText>
              </a>
            </StyledMenuItem>
          ) : (
            <StyledMenuItem key={child.text}>
              <Link to={child.link} target={child.target}>
                <StyledListItemText onClick={handleClose}>{child.text}</StyledListItemText>
              </Link>
            </StyledMenuItem>
          ),
        )}
      </StyledMenu>
    </NavlistItemWrapper>
  );
}
