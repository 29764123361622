import React from "react";
import {
  AboutUSImage,
  AboutUSContainer,
  AboutUSContentContainer,
  AboutUsTextFlexBox,
  AboutUSTag,
  AboutUSContentWrapper,
  AboutUSTextItemWrapper,
  AboutUSTagText,
  AboutUSTitle,
  AboutUSText,
  AboutUSHighlightedText,
} from "../../styledComponents";
import headerImage from "../../images/minified/Banner.jpg";
import MobileHeaderPicsSlider from "./MobileHeaderPicSlider";
import useWindowSize from "../../components/useWindowSize";

const AboutUs = () => {
  const size = useWindowSize();
  return (
    <AboutUSContainer>
      {size.width >= 701 && <AboutUSImage src={headerImage} />}
      {size.width <= 700 && <MobileHeaderPicsSlider />}
      <AboutUSContentContainer>
        <br />
        <AboutUSTag>
          {" "}
          <AboutUSTagText> Über uns </AboutUSTagText>
        </AboutUSTag>
        <AboutUsTextFlexBox>
          <AboutUSTitle>
            {" "}
            SKID bietet Lebensräume <br /> zur Selbstentwicklung{" "}
          </AboutUSTitle>{" "}
          <AboutUSContentWrapper>
            <div style={{ width: "auto", display: "block" }}>
              <AboutUSTextItemWrapper>
                {" "}
                <AboutUSText>
                  {" "}
                  SKID unterstützt die größtmögliche selbstständige
                  Lebensgestaltung von Menschen mit Assistenzbedarf.{" "}
                </AboutUSText>
              </AboutUSTextItemWrapper>

              <AboutUSTextItemWrapper>
                {" "}
                <AboutUSText>
                  {" "}
                  Die <AboutUSHighlightedText>S</AboutUSHighlightedText>ozial
                  <AboutUSHighlightedText>K</AboutUSHighlightedText>ulturellen
                  <AboutUSHighlightedText> I</AboutUSHighlightedText>ntegrations
                  <AboutUSHighlightedText>D</AboutUSHighlightedText>ienste
                  <AboutUSHighlightedText> S</AboutUSHighlightedText>KID gGmbH
                  wurden 2004 gegründet, um für Menschen mit Assistenzbedarf
                  berufliche Qualifikationen und gemeindeintegrierte Wohn- und
                  Arbeitsplätze zu schaffen.{" "}
                </AboutUSText>{" "}
              </AboutUSTextItemWrapper>

              <AboutUSTextItemWrapper>
                {" "}
                <AboutUSText>
                  {" "}
                  SKID ermöglicht den Klienten eine langfristige
                  Beschäftigungsperspektive in einem sicheren Umfeld.
                  <br />
                  Im Mittelpunkt steht die individuelle Förderung der eigenen
                  Möglichkeiten, die eine persönliche Entwicklung und die
                  Teilhabe an der Gesellschaft ermöglicht.{" "}
                </AboutUSText>
              </AboutUSTextItemWrapper>

              <AboutUSTextItemWrapper>
                {" "}
                <AboutUSText>
                  {" "}
                  Das Wohnangebot umfasst die{" "}
                  <AboutUSHighlightedText>
                    {" "}
                    gemeindeintegrierte besondere Wohnform und die Flexible Assistenzleistung.
                  </AboutUSHighlightedText>
                </AboutUSText>{" "}
              </AboutUSTextItemWrapper>

              <AboutUSTextItemWrapper>
                {" "}
                <AboutUSText>
                  {" "}
                  <AboutUSHighlightedText>
                    {" "}
                    Das Arbeitsangebot{" "}
                  </AboutUSHighlightedText>
                  qualifiziert die TeilnehmerInnen im Rahmen der WfbM.
                  Regelmäßige Praktika in Betrieben ergänzen die Erfahrungen und
                  können zu einem ausgelagerten WfbM-Arbeitsplatz oder/und zu
                  einem Übergang in den allgemeinen Arbeitsmarkt führen.{" "}
                </AboutUSText>
              </AboutUSTextItemWrapper>
            </div>{" "}
          </AboutUSContentWrapper>{" "}
        </AboutUsTextFlexBox>{" "}
      </AboutUSContentContainer>{" "}
    </AboutUSContainer>
  );
};

export default AboutUs;
