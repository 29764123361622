import "./App.css";
import {useState } from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AppBody } from "./styledComponents";
import useWindowSize from "./components/useWindowSize";
import LandingPage from "./LandingPage";
import GoldBachPage from "./components/arbeit-projects/goldBachSeite/GoldBachPage";
import BioBistroPage from "./components/arbeit-projects/BioBistroPage";
import NudelEmmaPage from "./components/arbeit-projects/NudelEmmaPage";
import NudelLadenPage from "./components/arbeit-projects/NudelLadenPage";
import BackMboilPage from "./components/arbeit-projects/BackMobilPage";
import NudelManufakturPage from "./components/arbeit-projects/NudelManufakturPage";
import DatenshutzImpressumPage from "./components/DatenschutzImpressum.js";
import ScrollButton from "./components/ScrollToTopButton.js";
function App() {
  const size = useWindowSize();

  const [themeMode, setThemeMode] = useState("light");
  const toggelTheme = () => {
    if (themeMode === "light") {
      setThemeMode("dark");
    } else {
      setThemeMode("light");
    }
  };
  const [darkModeClass, setDarkModeClass] = useState("");
  if(window.location.href && window.location.href.length > 0 && window.location.href.indexOf("#impressum") !== -1){
    if(darkModeClass === "") setDarkModeClass("btn-dm-p");
  }

 
  

  return (
    <Router>
      <ThemeProvider
        theme={{
          theme: themeMode,
          mode: size.width,
        }}>
        <AppBody className="App">
          {size.width >= 999 && (
            <div className={"label "+ darkModeClass} id="chk">
              <i className="fas fa-moon"></i>
              <i className="fas fa-sun"></i>
              <button
                onClick={toggelTheme}
                className={themeMode === "light" ? "ball" : "ballright"}
                id="chk"
              />
            </div>
          )}
          <Switch>
            <Route path="/Datenshutz">
              <DatenshutzImpressumPage />
            </Route>
            <Route path="/Nudel-Manufaktur">
              <NudelManufakturPage />
            </Route>
            <Route path="/Back-Mboil">
              {" "}
              <BackMboilPage />
            </Route>
            <Route path="/Nudel-Laden">
              {" "}
              <NudelLadenPage />
            </Route>
            <Route path="/Nudel-Emma">
              {" "}
              <NudelEmmaPage />
            </Route>
            <Route path="/Bio-Bistro">
              {" "}
              <BioBistroPage />
            </Route>
            <Route path="/Goldbach">
              {" "}
              <GoldBachPage />
            </Route>
            <Route path="/">
              {" "}
              <LandingPage />
            </Route>
          </Switch>

          <ScrollButton/>
        </AppBody>
      </ThemeProvider>
    </Router>
  );
}

export default App;
