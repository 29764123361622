import React from "react";
import { Link } from "react-router-dom";
import {
  Arbeitsection,
  AboutUSTag,
  AboutUSTagText,
  ArbeitProjectsWrapper,
  ArbeitProjectContainer,
  ArbeitImage,
  ArbeitProjectTextCon,
  ArbeitProjectProjectTitle,
  ArbeitProjectText,
  ArbeitTitleAndBtnCon,
  WohnenFreizeitProjectTitle,
  ArbeitTextSection,
  ArbeitTextWrapper,
  ArbeitTextTitle,
  ArbeitText,
  ArbeitOrangWord,
  ArbeitAngebotSvgWrapper,
  ArbeitAngebotTextContainer,
  ArbeitAngebotImg,
} from "../../styledComponents";
import goldBachPic from "../../images/minified/worksection/GoldbachStartseite-min.jpg";
import bistroPic from "../../images/minified/worksection/BistroStartseite-min.jpg";
import nudelManufakPic from "../../images/minified/worksection/NudelmanufakturStartseite-min.jpg";
import nudelEmmaPic from "../../images/minified/worksection/NudelemmaStartseite-min.jpg";
import nudelLadenPic from "../../images/minified/worksection/NudelladenStartseite-min.jpg";
import gbackMobilPic from "../../images/minified/worksection/BackmobilStartseite.jpg";
import beCreative from "../../images/beCreative.svg";
import GeschäftsleitungAgents from "./Geschäftsleitung";
import LightGreenButton from "../shared/Button";

export default function ArbeitSection() {
  const arbeitProjectsData = [
    {
      image: goldBachPic,
      title: "GOLDBACH",
      description: [
        "Brennholzverarbeitung",
        "Dienstleistungen & Fertigungsarbeiten ",
        "Wäscherei",
        "Kantine",
        "Kunstatelier",
      ],
      link: "/Goldbach",
      id: "goldbach-text",
    },
    {
      image: bistroPic,
      title: "SKIDS BIO BISTRO",
      description: [
        "SKIDs Bio Bistro in der Steinhausgasse mit täglich \n",
        "wechselndem Mittagstisch, außerdem Salate, Suppe,\n",
        "Kaffee & hausgemachte Kuchen.",
      ],
      link: "/Bio-Bistro",
      id: "bioBistro-text",
    },
    {
      image: nudelManufakPic,
      title: "ÜBERLINGER NUDELMANUFAKTUR",
      description: [
        "BIO-zertifizierte Nudeln aus eigener Produktion.",
        "Entdecken Sie unsere Seewogen, Hausmacher, Locken,",
        "Bandnudeln,... frisch oder getrocknet, als Steinpilz-, ",
        'rote Bete-, Spinatnudeln oder einfach "natur", mit oder ohne Ei.',
      ],
      link: "/Nudel-Manufaktur",
      id: "nudelManufaktur-text",
    },
    {
      image: nudelEmmaPic,
      title: "NUDELEMMA",
      description: [
        "Die Nudel-to-go.\n",
        "Mithilfe eines Dampfverfahrens bereiten wir in wenigen Minuten\n",
        "verschiedene Nudelgerichte mit unseren Hausmacher Bio-Nudeln \n",
        " und hausgemachten Bio-Saucen zu.",
      ],
      link: "/Nudel-Emma",
      id: "nudelEmma-text",
    },
    {
      image: nudelLadenPic,
      title: "ÜBERLINGER NUDELLADEN ",
      description: [
        "Alles rund um die Nudel.\n ",
        "Hausgemachte Bio-Nudeln, Bio-Lebensmittel und\n",
        "Feinkost in unserem Laden in der Christophstraße.",
      ],
      link: "/Nudel-Laden",
      id: "nudelLaden-text",
    },
    {
      image: gbackMobilPic,
      title: "BACKMOBIL",
      link: "/Back-Mboil",
      id: "backMobil-text",
      description: ["Flammkuchen mit unterschiedlichen Belägen."],
    },
  ];

  return (
    <Arbeitsection>
      <br />
      <AboutUSTag>
        <AboutUSTagText> Arbeit </AboutUSTagText>{" "}
      </AboutUSTag>{" "}
      <br />
      <ArbeitProjectsWrapper>
        {" "}
        {arbeitProjectsData.map((project) => (
          <ArbeitProjectContainer key={project.title}>
            <Link to={project.link} target="blank">
              <ArbeitImage src={project.image} />{" "}
            </Link>{" "}
            <ArbeitProjectTextCon>
              <ArbeitTitleAndBtnCon>
                <ArbeitProjectProjectTitle>
                  {project.title}
                </ArbeitProjectProjectTitle>
              </ArbeitTitleAndBtnCon>{" "}
              <div className="d-flex flex-column gap-03">
                {project.description.map((Obj) => (
                  <ArbeitProjectText
                    key={project.id + Math.floor(Math.random() * 100000)}>
                    {" "}
                    {Obj}{" "}
                  </ArbeitProjectText>
                ))}{" "}
              </div>
            </ArbeitProjectTextCon>{" "}
              <div className="pb-2 d-flex place-content-end mt-auto">
                <LightGreenButton linkTo={true} link={project.link}/>
              </div>
          </ArbeitProjectContainer>
        ))}{" "}
      </ArbeitProjectsWrapper>{" "}
      <ArbeitTextSection>
        <ArbeitTextWrapper>
          <ArbeitTextTitle>
            Die Identifikation mit der Arbeit bereichert die soziale und
            kulturelle Vielfalt eines Menschen{" "}
          </ArbeitTextTitle>{" "}
          <ArbeitText>
            SKID qualifiziert die TeilnehmerInnen im Rahmen der WfbM.{" "}
          </ArbeitText>{" "}
          <ArbeitText>
            SKID bietet den Berufsbildungsbereich (Dauer 24 Monate)
            einschließlich dem Eingangsverfahren (Dauer 3 Monate), sowie dem
            anschließenden Arbeitsbereich an.{" "}
          </ArbeitText>{" "}
          <ArbeitText>
            Regelmäßige Praktika in Betrieben ergänzen die Erfahrungen und
            können zu einem ausgelagerten WfbM - Arbeitsplatz oder/und zu einem
            Übergang in den allgemeinen Arbeitsmarkt führen.{" "}
          </ArbeitText>{" "}
          <ArbeitText>
            <ArbeitOrangWord>WiLAN</ArbeitOrangWord> -{" "}
            <ArbeitOrangWord>Wi</ArbeitOrangWord>ssen{" "}
            <ArbeitOrangWord>L</ArbeitOrangWord>ernen{" "}
            <ArbeitOrangWord>A</ArbeitOrangWord>usbildung{" "}
            <ArbeitOrangWord>N</ArbeitOrangWord>ormalität ist ein schulisches
            Angebot im Rahmen der WfbM und steht für lebenslanges Lernen. <br />
            <ArbeitOrangWord>WiLAN</ArbeitOrangWord> bietet ein breitgefächertes
            Bildungs - , Kultur - und Freizeitangebot an.{" "}
          </ArbeitText>{" "}
        </ArbeitTextWrapper>{" "}
        <ArbeitAngebotSvgWrapper>
          <ArbeitAngebotTextContainer>
            <WohnenFreizeitProjectTitle style={{ padding: "0 0 10px 0" }}>
              Angebote für{" "}
            </WohnenFreizeitProjectTitle>{" "}
            <ArbeitText>
              Menschen mit geistiger und/oder seelischer Behinderung{" "}
            </ArbeitText>{" "}
            <ArbeitText>
              Menschen, die sich für die Anforderungen auf dem allgemeinen{" "}
              <br />
              Arbeitsmarkt qualifizieren möchten{" "}
            </ArbeitText>{" "}
          </ArbeitAngebotTextContainer>{" "}
          <ArbeitAngebotImg src={beCreative} />{" "}
        </ArbeitAngebotSvgWrapper>{" "}
      </ArbeitTextSection>{" "}
      <GeschäftsleitungAgents />
    </Arbeitsection>
  );
}
