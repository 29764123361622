import React from "react";
import { AboutUSTag, AboutUSTagText } from "../../styledComponents";
import {
  UnerheortBody,
  UnerheortTextContainer,
  UnerheortTitle,
  UnerheortText,
  UnerheortTextOrange,
  UnerheortTextLightGreen
} from "./styledComponents";

function Unerheort() {
  return (
    <UnerheortBody id="KunstUnerhrort">
      <br />
      <AboutUSTag>
        <AboutUSTagText>"UNERHÖRT!"</AboutUSTagText>{" "}
      </AboutUSTag>{" "}
      <br />
      <UnerheortTextContainer>
        <UnerheortTitle>
          Im Frühjahr 2017 startete das Hörspielprojekt "UNERHÖRT!".
        </UnerheortTitle>
        <UnerheortText>
          Ein Hörspiel mit dem Titel «Warum das Meerwasser salzig ist» war der
          Auftakt für das Projekt «UNERHÖRT!», das mit 8 SKID-Klienten in professionellem Rahmen
          realisiert wurde.
        </UnerheortText>
        <UnerheortText>
          Weitere Produktionen sollen folgen.
        </UnerheortText>{" "}
        <UnerheortText  className="d-flex align-items-center gap-5px">
          <UnerheortTextLightGreen
            href="https://soundcloud.com/user-808080561/warum-das-meerwasser-salzig-ist"
            rel="noreferrer"
            target="_blank"
            className="d-flex align-items-center">
              Hier
          </UnerheortTextLightGreen>
          können Sie das Hörspiel anhören.
        </UnerheortText>
        <UnerheortText>
          Weitere Informationen finden Sie auch auf der
          <UnerheortTextOrange
            href="https://ce8.art.blog/projekte/"
            rel="noreferrer"
            target="_blank">
            {" "}
            Internetseite der Kunstgalerie.
          </UnerheortTextOrange>
        </UnerheortText>{" "}
      </UnerheortTextContainer>
    </UnerheortBody>
  );
}

export default Unerheort;
