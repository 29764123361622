import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import { rootStyles, NavlistItemWrapper, NavlistItemText } from "../../styledComponents";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #522032",
    backgroundColor: "#522030",
    color: "white",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary,": {
        color: "",
      },
    },
    "&:hover":{
      backgroundColor: rootStyles.orange,
    }
  },
}))(MenuItem);

const StyledListItemText = withStyles((theme) => ({
  root: {
    "& .MuiTypography-body1":{
      fontSize: rootStyles.fontSize14,
      fontWeight: rootStyles.fontWeight600,
      fontFamily:"inherit"
    }
  },
}))(ListItemText);

const StyledButton = withStyles((theme) => ({
  root: {
    padding: "0",
    borderBottom: "2px solid #522032",
    borderRadius: "0",
    minWidth: "47px",
    fontSize: rootStyles.fontSize14,
    fontWeight: rootStyles.fontWeight600,
    color: "white",
    "&:hover":{
      borderBottom: "2px solid "+rootStyles.orange,
    }
  },
}))(Button);

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const sendClose = () =>{
    setAnchorEl(null);
    props.close();
  }
  return (
    <NavlistItemWrapper>
      <NavlistItemText>
        <StyledButton
          id={props.listName}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}>
          {props.listName}
        </StyledButton>
      </NavlistItemText>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {props.children.map((child) =>
          child.target === null ? (
            <StyledMenuItem key={child.text}>
              <a href={child.link}>
                <StyledListItemText onClick={handleClose}>{child.text}</StyledListItemText>
              </a>
            </StyledMenuItem>
          ) : (
            <StyledMenuItem key={child.text}>
              <Link to={child.link} target={child.target}>
                <StyledListItemText onClick={sendClose}>{child.text}</StyledListItemText>
              </Link>
            </StyledMenuItem>
          ),
        )}
      </StyledMenu>
    </NavlistItemWrapper>
  );
}
