import React from "react";
import {
  BodyContainer,
  TagWrapper,
  TagText,
  ImageMain,
  ContentWrapper,
  TextWrapper,
  TextContainer,
  SkidList,
  TextRightContainer,
  HeaderTitle,
  ContentTitle,
  OrangeLink,BioBistroOrangeText,
} from "./goldBachSeite/styledCom.js";
import LogoPanner from "../arbeit-projects/HeaderLogoPanner";
import bistroHeaderPic from "../../images/Bistro.jpg";
import AgentCard from "./goldBachSeite/ArbeitAgentCard";
import { bioBistroAgentData } from "./goldBachSeite/projectsData.js";

import Footer from "../footer/Footer.js";

function index() {
  return (
    <BodyContainer>
      <LogoPanner />
      <TagWrapper>
        <TagText> SKIDS BIO BISTRO </TagText>{" "}
      </TagWrapper>{" "}
      <br />
      <ImageMain src={bistroHeaderPic} />{" "}
      <ContentWrapper>
        <HeaderTitle>
          In charmanter und ruhiger Atmosphäre genießen Sie bei uns täglich
          frisch zubereitete Speisen aus 100 % regional biologischen Produkten{" "}
        </HeaderTitle>{" "}
        <TextWrapper style={{ lineHeight: "25px", paddingTop: "20px" }}>
          <TextContainer>
            <ContentTitle style={{ paddingBottom: "15px" }}>
              {" "}
              Wir bieten Ihnen{" "}
            </ContentTitle>
            <SkidList>
              <li>
                Täglich wechselnder Mittagstisch & wöchentlich wechselnde Suppe
              </li>{" "}
              <OrangeLink
                href="https://www.skids-bistro.de/#nudeln"
                target="blank">
                {" "}
                <li>Nudelgerichte </li>{" "}
              </OrangeLink>{" "}
              <li>Salate </li> <li>Hausgemachte Kuchen </li>{" "}
              <li>Bio Kaffee in allen Variationen </li>{" "}
              <li>Und vieles mehr </li>
            </SkidList>
          </TextContainer>{" "}
          <TextRightContainer>
            <ContentTitle style={{ paddingBottom: "10px" }}>
              {" "}
              WISSENSWERTES{" "}
            </ContentTitle>
            <SkidList>
              <li>
                {" "}
                Website:
                <OrangeLink href="https://www.skids-bistro.de" target="blank">
                  &nbsp; www.skids-bistro.de{" "}
                </OrangeLink>{" "}
              </li>{" "}
              <li>
                Mittagstisch & Nudelgerichte <BioBistroOrangeText>Mo - Fr von 12: 00 - 14: 00 Uhr</BioBistroOrangeText>
              </li>{" "}
              <li>Alle Gerichte auch zum Mitnehmen </li>{" "}
              <li>Bio zertifiziert nach DE - ÖKO - 007 </li>{" "}
              <li>Besuchen Sie uns und kosten Sie unsere Gerichte </li>{" "}
            </SkidList>
          </TextRightContainer>{" "}
        </TextWrapper>{" "}
      </ContentWrapper>{" "}
      <AgentCard
        agentPic={bioBistroAgentData.agentPic}
        fullName={bioBistroAgentData.fullName}
        tel={bioBistroAgentData.tel}
        email={bioBistroAgentData.email}
        address={bioBistroAgentData.address}
        mapPic={bioBistroAgentData.mapPic}
        googleMaps={bioBistroAgentData.googleMaps}
      />{" "}
      <Footer />
    </BodyContainer>
  );
}

export default index;
