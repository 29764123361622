import React from "react";
import {
  BodyContainer,
  TagWrapper,
  TagText,
  ImageMain,
  ContentWrapper,
  TextWrapper,
  TextContainer,
  TextRightContainer,
  HeaderTitle,
  ContentTitle,SkidList
} from "./styledCom.js";
import LogoPanner from "../../arbeit-projects/HeaderLogoPanner";
import dienstPic from "../../../images/minified/goldbachsection/dienstleistungen-min.jpg";
import AgentCard from "./ArbeitAgentCard";
import { dienstLeistungData, kucheData, wascheData, goldBachKunstData } from "./projectsData.js";
import BrenHolz from "./BrenHolzPage";
import ProjctComponentBuilder from "./ArbeitProjectComponent";
import Footer from "../../../components/footer/Footer.js";

function index() {
  return (
    <BodyContainer>
      <LogoPanner />
      <TagWrapper>
        <TagText>DIENSTLEISTUNGS- UND FERTIGUNGSARBEITEN</TagText>
      </TagWrapper>
      <br />
      <ImageMain src={dienstPic} />
      <ContentWrapper> 
        <ContentTitle>
          Wir bieten Ihnen Dienstleistungsangebote wie:
        </ContentTitle>
        <TextWrapper>
          <TextContainer>
            <SkidList>
            <li>Entrümpelungen</li>
            <li>Aufräumarbeiten</li>
            <li>Kleintransporte</li></SkidList>
          </TextContainer>
          <TextRightContainer>
          <SkidList>
            <li>Metall recyclen</li>
            <li>Abholung von Schrott und Edelmetallen</li>
            <li>Fertigungsarbeiten nach Absprache wie z.B. Holzarbeiten</li></SkidList>
          </TextRightContainer>
        </TextWrapper>
        <HeaderTitle className="pt-1">
          Haben Sie Interesse an unseren Angeboten, dann rufen Sie uns an oder
          besuchen Sie unsere Werkstatt!
        </HeaderTitle>
      </ContentWrapper>
      <AgentCard
        agentPic={dienstLeistungData.agentPic}
        fullName={dienstLeistungData.fullName}
        mobile={dienstLeistungData.mobile}
        tel={dienstLeistungData.tel}
        email={dienstLeistungData.email}
        address={dienstLeistungData.address}
        mapPic={dienstLeistungData.mapPic}
        googleMaps={dienstLeistungData.googleMaps}
      />
      <br />
      <BrenHolz />
      <ProjctComponentBuilder
        tagTitle={kucheData.tagTitle}
        headerPic={kucheData.headerPic}
        linksText={kucheData.linksText}
        rechtsText={kucheData.rechtsText}
        orangeText={kucheData.orangeText}
        agentPic={kucheData.agentPic}
        fullName={kucheData.fullName}
        mobile={kucheData.mobile}
        tel={kucheData.tel}
        email={kucheData.email}
        address={kucheData.address}
        mapPic={kucheData.mapPic}
        googleMaps={kucheData.googleMaps}
      />
      <ProjctComponentBuilder
        tagTitle={wascheData.tagTitle}
        headerPic={wascheData.headerPic}
        linksText={wascheData.linksText}
        rechtsText={wascheData.rechtsText}
        orangeText={wascheData.orangeText}
        agentPic={wascheData.agentPic}
        fullName={wascheData.fullName}
        mobile={wascheData.mobile}
        tel={wascheData.tel}
        email={wascheData.email}
        address={wascheData.address}
        mapPic={wascheData.mapPic}
        googleMaps={wascheData.googleMaps}
      />
      <ProjctComponentBuilder
        tagTitle={goldBachKunstData.tagTitle}
        headerPic={goldBachKunstData.headerPic}
        linksText={goldBachKunstData.linksText}
        rechtsText={goldBachKunstData.rechtsText}
        orangeText={goldBachKunstData.orangeText}
        agentPic={goldBachKunstData.agentPic}
        fullName={goldBachKunstData.fullName}
        mobile={goldBachKunstData.mobile}
        tel={goldBachKunstData.tel}
        email={goldBachKunstData.email}
        address={goldBachKunstData.address}
        mapPic={goldBachKunstData.mapPic}
        googleMaps={goldBachKunstData.googleMaps}
      />
      <Footer />
    </BodyContainer>
  );
}

export default index;
