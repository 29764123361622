import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import {
  WohnenFreizeitContentContainer,
  AboutUSTag,
  AboutUSTagText,
  WohnenFreizeitImagesContiner,
  WohnenFreizeitImageWrapper,
  WohnenFreizeitRightImageWrapper,
  WohnenFreizeitImage,
  WohnenFreizeitProjectTextWrapper,
  WohnenFreizeitProjectAbwTextWrapper,
  WohnenFreizeitProjectTitle,
  WohnenFreizeitOrangeLetter,
  WohnenFreizeitAngebotWrapper,
  AbwSvgImage,
  WohnenFreizeitAngebotContainerText,
  WohnenFreizeitAngebotContainerImage,
  WohnenFreizeitAngebotHelpOthersSVG,
  WohnenFreizeitBtnWrapper,
} from "../../styledComponents";
import GIWImage from "../../images/minified/giw-min.jpg";
import ABWImage from "../../images/minified/fa-min.jpg";
import SvgHousing from "../../images/apartment-black-18dp.svg";
import SvgAngebot from "../../images/helpOthers.svg";
import AbwDropDown from "./AbwDropDown";
import useWindowSize from "../../components/useWindowSize";
import LightGreenButton from "../../components/shared/Button";

function WohnenFreizeit() {
  const [readMoreOnWohnen, setReadMoreOnWohnen] = useState(false);

  const readmore = useCallback(() => {
    setReadMoreOnWohnen(!readMoreOnWohnen);
  }, [readMoreOnWohnen]);

  const size = useWindowSize();
  useEffect(() => {
    document
      .getElementById("Wohnen & Freizeit")
      .addEventListener("click", readmore);
  }, [readmore, size.width]);

  return (
    <>
      <WohnenFreizeitContentContainer id="WohnenFreizeit">
        <br />
        <AboutUSTag>
          <AboutUSTagText> Wohnen & Freizeit </AboutUSTagText>{" "}
        </AboutUSTag>{" "}
        <br />
        <WohnenFreizeitImagesContiner>
          <WohnenFreizeitImageWrapper>
            <a href="#GiwSection">
              <WohnenFreizeitImage onClick={readmore} src={GIWImage} />{" "}
            </a>{" "}
            <WohnenFreizeitProjectTextWrapper>
              <WohnenFreizeitProjectTitle> GIW </WohnenFreizeitProjectTitle>{" "}
              <br />
              <WohnenFreizeitOrangeLetter>
                G
              </WohnenFreizeitOrangeLetter>emeinde<WohnenFreizeitOrangeLetter>i</WohnenFreizeitOrangeLetter>ntegriertes
              <WohnenFreizeitOrangeLetter>
                {" "}
                W
              </WohnenFreizeitOrangeLetter>ohnen <br />
              Wohnen in der Gemeinde/Stadt mit sozialraumorientierter<br/>und
              individueller Assistenz - besondere Wohnformen{" "}
            </WohnenFreizeitProjectTextWrapper>{" "}
          </WohnenFreizeitImageWrapper>{" "}
          <WohnenFreizeitRightImageWrapper>
            <a href="#FASection">
              {" "}
              <WohnenFreizeitImage onClick={readmore} src={ABWImage} />{" "}
            </a>{" "}
            <WohnenFreizeitProjectAbwTextWrapper>
              <div>
                <WohnenFreizeitProjectTitle> FA </WohnenFreizeitProjectTitle>{" "}
                <br />
                <span>
                  <WohnenFreizeitOrangeLetter> F</WohnenFreizeitOrangeLetter>
                  lexible
                  <WohnenFreizeitOrangeLetter> A</WohnenFreizeitOrangeLetter>
                  ssistenzleistung (früher: ABW)
                  <br />
                  Einzelwohnen <br />
                  Paarwohnen <br />
                  Wohngemeinschaften{" "}
                </span>{" "}
              </div>{" "}
              <AbwSvgImage src={SvgHousing} />{" "}
            </WohnenFreizeitProjectAbwTextWrapper>{" "}
          </WohnenFreizeitRightImageWrapper>{" "}
          <br />
          <WohnenFreizeitAngebotWrapper>
            <WohnenFreizeitAngebotContainerText>
              <div style={{ padding: "0px 0px 10px 0px", lineHeight: "2rem" }}>
                <WohnenFreizeitProjectTitle>
                  Angebot des persönlichen Budgets{" "}
                </WohnenFreizeitProjectTitle>{" "}
                <p>
                  {" "}
                  Individuelle Maßnahmen im Rahmen des persönlichen Budgets{" "}
                </p>{" "}
              </div>{" "}
              <div style={{ padding: "0px 0px 10px 0px", lineHeight: "2rem" }}>
                <WohnenFreizeitProjectTitle>
                  Angebot für{" "}
                </WohnenFreizeitProjectTitle>{" "}
                <p>
                  Erwachsene Menschen mit geistiger und/oder seelischer
                  Behinderung{" "}
                </p>{" "}
              </div>{" "}
            </WohnenFreizeitAngebotContainerText>{" "}
            <WohnenFreizeitAngebotContainerImage>
              <WohnenFreizeitAngebotHelpOthersSVG src={SvgAngebot} />{" "}
            </WohnenFreizeitAngebotContainerImage>{" "}
          </WohnenFreizeitAngebotWrapper>{" "}
        </WohnenFreizeitImagesContiner>{" "}
        {readMoreOnWohnen === false && (
          <WohnenFreizeitBtnWrapper>
            <LightGreenButton action={readmore}/>
          </WohnenFreizeitBtnWrapper>
        )}{" "}
      </WohnenFreizeitContentContainer>{" "}
      {readMoreOnWohnen === true && <AbwDropDown readMoreOnWohnen={readmore} />}{" "}
    </>
  );
}

export default WohnenFreizeit;
