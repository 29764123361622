import React from "react";

import { AboutUSTag, AboutUSTagText } from "../../styledComponents";
import {
  KunstInternationalBody,
  KunstInternationalImg,
  KunstInternationalTextContiner,
  KunstInternationalText,
} from "./styledComponents";
import pic from "../../images/minified/artsection/InternatKunstStartseite-min.jpg";
import GallerieCe from "./GalerieCe8";
import Unerheort from "./Unerheort";
import LightGreenButton from "../../components/shared/Button";

function KunstInetrTeamWork(props) {
  return (
    <KunstInternationalBody id="KunstTeamWork">
      <br />
      <AboutUSTag>
        <AboutUSTagText>
          Internationale, künstlerische Zusammenarbeit
        </AboutUSTagText>
      </AboutUSTag>
      <br />
      <KunstInternationalImg src={pic} />
      <KunstInternationalTextContiner>
        <KunstInternationalText>
          Gemeinsam mit drei weiteren europäischen Partnerorganisationen in Luxemburg, England und Irland führen wir
          internationale Kunstworkshops durch. 
        </KunstInternationalText>
      </KunstInternationalTextContiner>
      <GallerieCe />
      <Unerheort />
      <LightGreenButton action={props.func} label={'weniger erfahren'}/>
    </KunstInternationalBody>
  );
}

export default KunstInetrTeamWork;
