import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import NavList from "./NavListItemsMobil";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import {
  rootStyles,
  MobileNavlistWrapper,
  MobileNavBodyWrapper,
} from "../../styledComponents";
import ArbeitNavList from "./ArbeitNavListItemsMobil";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#522032",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const StyledIconButton = withStyles((theme) => ({
  root: {
    "&:hover":{
      color:rootStyles.orange,
      backgroundColor:"transparent"
    }
  },
}))(IconButton);

export default function PersistentDrawerRight() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const wohnenList = {
    listname: "Wohnen & Freizeit",
    children: [
      { text: "Über Wohnen und Freizeit", link: "#WohnenFreizeit" },
      { text: "Gemeindeintegriertes Wohnen – GIW ", link: "#GiwSection" },
      {
        text: "Flexible Assistenzleistung – FA",
        link: "#FASection",
      },
    ],
  };
  const arbeitList = {
    listname: "Arbeit",
    children: [
      { text: "Über Arbeit", link: "#ArbeitSection", target: null },
      { text: "GOLDBACH", link: "/Goldbach", target: "_blank" },
      { text: "SKIDS BIO BISTRO", link: "/Bio-Bistro", target: "_blank" },
      {
        text: "ÜBERLINGER NUDELMANUFAKTUR",
        link: "/Nudel-Manufaktur",
        target: "_blank",
      },
      { text: "NUDELEMMA", link: "/Nudel-Emma", target: "_blank" },
      {
        text: "ÜBERLINGER NUDELLADEN ",
        link: "/Nudel-Laden",
        target: "_blank",
      },
      { text: "BACKMOBIL", link: "/Back-Mboil", target: "_blank" },
    ],
  };

  const kunstList = {
    listname: "Kunst",
    children: [
      { text: "Über Kunst", link: "#KunstSection" },
      {
        text: "Internationale, künstlerische Zusammenarbeit",
        link: "#KunstTeamWork",
      },
      { text: "Galerie Käsering", link: "#KunstCe8" },
      { text: '"UNERHÖRT!"', link: "#KunstUnerhrort" },
    ],
  };
  const stellen_ausbildungList = {
    listname: "Stellen/Ausbildung",
    children: [
      { text: "Ausbildung ", link: "#AusbildungSection" },
      { text: "Offene Stellen", link: "#OffneStellenSection" },
    ],
  };
  return (
    <MobileNavlistWrapper>
      <CssBaseline />
      <StyledIconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        className={clsx(open && classes.hide)}>
        <i className="fas fa-bars"></i>
      </StyledIconButton>
      <main
        style={{ padding: "0", marginLeft: "0" }}
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}>
        <div className={classes.drawerHeader} />
      </main>
      <MobileNavBodyWrapper>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  width="18px"
                  height="18px">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  width="18px"
                  height="18px">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                </svg>
              )}
            </IconButton>
          </div>
          <List style={{ padding: "8px 16px" }}>
            <NavList
              className="navListW navListWraperMobile"
              listName={wohnenList.listname}
              children={wohnenList.children}
              close={handleDrawerClose}
            />
            <br />
            <ArbeitNavList
              className="navListW"
              listName={arbeitList.listname}
              children={arbeitList.children}
              close={handleDrawerClose}
            />
            <br />
            <NavList
              className="navListW navListWraperMobile"
              listName={kunstList.listname}
              children={kunstList.children}
              close={handleDrawerClose}
            />
            <br />
            <NavList
              className="navListW navListWraperMobile"
              listName={stellen_ausbildungList.listname}
              children={stellen_ausbildungList.children}
              close={handleDrawerClose}
            />
          </List>
        </Drawer>
      </MobileNavBodyWrapper>
    </MobileNavlistWrapper>
  );
}
