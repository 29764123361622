import React from "react";
import {
  GiwAbwBody,
  GiwAbwWrapper,
  WohnenFreizeitProjectTitle,
  GiwAbwBodyText,
  GiwAbwBodyListTitle,
} from "../../styledComponents";
import AgentCard from "../../components/AgentCard.js";
import GiwAgentPic from "../../images/WohnenGIWGunther.png";
import GiwAgentMap from "../../images/kronengasse1.png";

const GiwAbwDropDown = () => {
  const giwAgentInfo = {
    agentPic: GiwAgentPic,
    fullName: "Gunther Köplin",
    jobTitle: "Heimleitung",
    tel: "Tel. 0176 47048080",
    email: "E-mail: g.koeplin@skid-ggmbh.de",
    address: "Kronengasse 1 88662 Überlingen",
    mapPic: GiwAgentMap,
    googleMaps: "https://goo.gl/maps/o4tCnrXAqt12LnLw8",
  };
  return (
    <GiwAbwBody id="mainWF">
      <GiwAbwWrapper id="GiwSection">
        <WohnenFreizeitProjectTitle style={{ padding: "30px 0px" }}>
          Gemeindeintegriertes Wohnen – GIW{" "}
        </WohnenFreizeitProjectTitle>
        <GiwAbwBodyText>
          Das Gemeindeintegrierte Wohnen richtet sich an Personen mit einer
          geistigen und/oder seelischen Behinderung, die individuelle
          Unterstützung in der Alltagsbewältigung und Lebensführung benötigen,
          um am Leben in der Gesellschaft teilzuhaben.
        </GiwAbwBodyText>
        <GiwAbwBodyText>
          Das Gemeindeintegrierte Wohnen umfasst Leistungen zur sozialen
          Teilhabe zur Unterstützung einer möglichst selbständigen
          Lebensführung.
          <br />
          Es handelt sich um eine Unterstützungsform in Vollversorgung in
          Wohngemeinschaften im Zentrum von Überlingen am Bodensee. <br />
          Der Umfang der Leistung ist eingeteilt in Früh- und Spätdienste an
          Werktagen (vor und nach der Arbeit) sowie ganztägigen
          Wochenenddiensten. <br />
          Bedarfsgerecht werden sowohl Nachtbereitschaften als auch
          Rufbereitschaften bereitgestellt.
          <br />
          Ausgangspunkt unserer Arbeit ist die personenzentrierte Arbeit auf der
          Basis eines individuellen Assistenzplans, der auf die persönlichen
          Bedürfnisse und Fähigkeiten jedes einzelnen Klienten abgestimmt ist.
        </GiwAbwBodyText>
        <GiwAbwBodyText>
          Das Prinzip „Hilfe zur Selbsthilfe“ und die Unterstützung zur
          größtmöglichen Selbständigkeit stehen im Zentrum unserer Arbeit.
          <br /> Die wertschätzende Kommunikation auf Augenhöhe ist dabei unser
          wichtigster Grundsatz.
          <br />
          Eine gute Beziehungsgestaltung, ressourcenorientiertes Arbeiten und
          transparente, klar strukturierte Abläufe sind positive Wirkfaktoren
          auf dem Weg zu Selbstkompetenz und selbstbestimmter Lebensführung.
        </GiwAbwBodyText>

        <GiwAbwBodyListTitle>
          Individuelle Assistenzleistungen:
        </GiwAbwBodyListTitle>
        <ul style={{ padding: "0px 0px 15px 15px" }}>
          <li>Gespräche über die persönliche Situation</li>
          <li>Förderung und Entwicklung lebenspraktischer Fähigkeiten</li>
          <li>
            Unterstützung im Wohnbereich, Selbstversorgung, Hygiene, Umgang mit
            Finanzen, tägliche Haushaltsführung
          </li>
          <li>
            Konfliktlösungsmöglichkeiten für Krisen und Veränderungssituationen
          </li>
          <li>
            Unterstützung im Kontakt mit medizinischen und sozialen Diensten
            sowie beim Umgang mit Behörden und sonstigen Institutionen
          </li>
          <li>Anregung und Beratung zur Erweiterung des Lebenskreises</li>
          <li>
            Begleitung und Beratung in Fragen der Arbeits- und
            Beschäftigungsmöglichkeiten, sowie gruppenspezifische Angebote in
            den Bereichen Bildung und Freizeit
          </li>
          <li>
            Gemeinschaftliche Angebote innerhalb und außerhalb der
            Wohngemeinschaft (z.B. Spieleabende, Fußball- und Schwimmgruppen,
            Sportgruppen, therapeutisches Reiten, Ausflüge, gemeinsame
            Urlaubsreisen)
          </li>
        </ul>

        <GiwAbwBodyListTitle>Formale Aufnahmekriterien</GiwAbwBodyListTitle>
        <GiwAbwBodyText>
          Die Kosten werden vom zuständigen Sozialhilfeträger übernommen, wenn
          die entsprechenden Voraussetzungen erfüllt sind.
        </GiwAbwBodyText>
      </GiwAbwWrapper>
      <AgentCard
        agentPic={giwAgentInfo.agentPic}
        fullName={giwAgentInfo.fullName}
        jobTitle={giwAgentInfo.jobTitle}
        tel={giwAgentInfo.tel}
        email={giwAgentInfo.email}
        address={giwAgentInfo.address}
        mapPic={giwAgentInfo.mapPic}
        googleMaps={giwAgentInfo.googleMaps}
      />
    </GiwAbwBody>
  );
};

export default GiwAbwDropDown;
