import React from 'react'
import marked from 'marked'
import {
    StellenTitle,JobOfferSection,
    JobOfferBody
  } from "./jobOfferElements";
const Post = ({ article }) => {
    //console.log(article);
    const { name, description } = article
    const postDescription = marked(description)
    return (
        <JobOfferBody>
            <StellenTitle>{name}</StellenTitle>
            <JobOfferSection dangerouslySetInnerHTML={{ __html: postDescription }} />
        </JobOfferBody>
    )
}

export default Post