import React from 'react'
import JobOffer from './JobOffer'

const Posts = ({ x }) => {
    return (
        <div>
            {x.map((article, index) => <JobOffer article={article} key={index} />)}
        </div>
    )
}

export default Posts
