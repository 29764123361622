import "../logo-panner/LogoPanner.css";
import logo from "../logo-panner/logo.png";
import {
  BlackLogoText,
  OrangeLogoText,
  LogoImage,
  NavlistWrapper,
  LogoContainer,
  LogoWrapper,
  LogoTextWrapper,
  StyledButtonx,
  NavlistItemText,
  NavlistItemWrapper,
} from "../../styledComponents.js";

import ArbeitNavList from "../logo-panner/ArbeitNavList";
import { Link } from "react-router-dom";

function LogoPanner() {
  const homePage = {
    listname: "Home",
    children: [{ text: "", link: "/" }],
  };

  const arbeitList = {
    listname: "Arbeit",
    children: [
      { text: "Über Arbeit", link: "/?sec=ArbeitSection" },
      { text: "GOLDBACH", link: "/Goldbach" },
      { text: "SKIDS BIO BISTRO", link: "/Bio-Bistro" },
      { text: "ÜBERLINGER NUDELMANUFAKTUR", link: "/Nudel-Manufaktur" },
      { text: "NUDELEMMA", link: "/Nudel-Emma" },
      { text: "ÜBERLINGER NUDELLADEN ", link: "/Nudel-Laden" },
      { text: "BACKMOBIL", link: "/Back-Mboil" },
    ],
  };

  return (
    <LogoContainer>
      <LogoWrapper>
        <Link to={'/'}>
          <LogoImage className="logo-img" src={logo} alt="logo Foto" />
        </Link>
        <LogoTextWrapper>
          {" "}
          <OrangeLogoText>S</OrangeLogoText>
          <BlackLogoText>
            OZIAL<OrangeLogoText>K</OrangeLogoText>ULTURELLE
            <OrangeLogoText>&#32;I</OrangeLogoText>NTEGRATIONS
            <OrangeLogoText>D</OrangeLogoText>IENSTE
          </BlackLogoText>
        </LogoTextWrapper>
      </LogoWrapper>
      <NavlistWrapper>
        <NavlistItemWrapper>
          <NavlistItemText>
            <StyledButtonx
              id={homePage.listName}
              aria-controls="simple-menu"
              aria-haspopup="false">
              <Link to="/">Home </Link>
            </StyledButtonx>
          </NavlistItemText>
        </NavlistItemWrapper>
        <ArbeitNavList
          className="navListW"
          listName={arbeitList.listname}
          children={arbeitList.children}
        />
      </NavlistWrapper>
    </LogoContainer>
  );
}

export default LogoPanner;
